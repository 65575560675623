import { useEffect } from 'react';
import { Button, Flex, Heading, Spinner } from '@chakra-ui/react';
import { useConfirmEmailMutation } from '@generated/graphql';
import { Centered } from '@layout';
import { useQueryString } from '@carafe/components';
import { useHistory } from 'react-router-dom';
import { Routes } from '@routes';
import { FormLogo } from '@components/forms';
import { LightText, MediumText } from '@carafe/components';
import { strings } from '@localisation';

export const ConfirmEmail = (): JSX.Element => {
  const [code, email] = useQueryString(['code', 'email']);
  const [{ data, error }, execute] = useConfirmEmailMutation();
  const history = useHistory();

  useEffect(() => {
    if (code && email) {
      execute({ data: { code, email } });
    }
  }, [code, email, execute]);

  if (error) {
    return (
      <Centered>
        <Flex
          direction="column"
          minH="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <FormLogo />
          <MediumText text={strings.confirmEmail.error} />
          <LightText text={strings.confirmEmail.contact} />
          <Button
            mt="1em"
            colorScheme="teal"
            onClick={() => history.push(Routes.SignInSignUp)}
          >
            {strings.confirmEmail.cta}
          </Button>
        </Flex>
      </Centered>
    );
  }

  if (data && data.confirmEmail) {
    return (
      <Centered>
        <Flex
          direction="column"
          minH="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <FormLogo />
          <Heading>{strings.confirmEmail.title}</Heading>
          <MediumText mt="1em" text={strings.confirmEmail.description} />
          <p>
            {strings.formatString(
              strings.confirmEmail.secondaryDescription,
              <b>{email}</b>,
            )}
          </p>
          <Button
            mt="1em"
            colorScheme="teal"
            onClick={() => history.push(Routes.SignInSignUp)}
          >
            {strings.confirmEmail.cta}
          </Button>
        </Flex>
      </Centered>
    );
  }

  return (
    <Centered>
      <Spinner color="teal.600" />
    </Centered>
  );
};
