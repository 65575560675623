import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme, WithChildren } from '@carafe/components';
import { Routes } from '@routes';
import {
  AppLogin,
  SsoAuthError,
  ConfirmEmail,
  CustomerCode,
  Dashboard,
  ForgotPassword,
  GoogleCallback,
  InvitationExpired,
  ResetPassword,
  SignInSignUp,
  VintraceLogin,
  AppleCallback,
  AppleSignIn,
  MicrosoftCallback,
  SignInWithError,
  AdminSignIn,
  OktaCallback,
} from '@pages';
import { AccountProvider } from '@account';
import { usePageView } from '@hooks';
import { AuthProvider } from '@auth';
import { AccountSetup } from '@pages/AccountSetup';

const Providers = ({ children }: WithChildren) => {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>{children}</AuthProvider>
    </ChakraProvider>
  );
};

const AppRoutes = () => {
  usePageView();
  return (
    <Switch>
      <Route path={Routes.SignInWithError} component={SignInWithError} />
      <Route path={Routes.AppleSignIn} component={AppleSignIn} />
      <Route exact path={Routes.AppleCallback} component={AppleCallback} />
      <Route
        exact
        path={Routes.MicrosoftCallback}
        component={MicrosoftCallback}
      />
      <Route exact path={Routes.GoogleCallback} component={GoogleCallback} />
      <Route exact path={Routes.OktaCallback} component={OktaCallback} />
      <Route exact path={Routes.SsoAuthError} component={SsoAuthError} />
      <Route exact path={Routes.CustomerCode} component={CustomerCode} />
      <Route exact path={Routes.Dashboard} component={Dashboard} />
      <Route exact path={Routes.ConfirmEmail} component={ConfirmEmail} />
      <Route exact path={Routes.AccountSetup} component={AccountSetup} />
      <Route path={Routes.ForgotPassword} component={ForgotPassword} />
      <Route exact path={Routes.ResetPassword} component={ResetPassword} />
      <Route
        exact
        path={Routes.InvitationExpired}
        component={InvitationExpired}
      />
      <Route path={Routes.VintraceLogin} component={VintraceLogin} />
      <Route path={Routes.AppLogin} component={AppLogin} />
      <Route exact path={Routes.AdminSignIn} component={AdminSignIn} />
      <AccountProvider>
        <Route exact path={Routes.Home} component={CustomerCode} />
        <Route exact path={Routes.SignInSignUp} component={SignInSignUp} />
      </AccountProvider>
      <Redirect to={Routes.Home} />
    </Switch>
  );
};

const App = (): JSX.Element => {
  // router must be outside appRoutes for useLocation to work
  return (
    <Providers>
      <Router>
        <AppRoutes />
      </Router>
    </Providers>
  );
};

export default App;
