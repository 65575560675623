import { Col, NormalText, LightText, NotFound } from '@carafe/components';
import { Button, Flex } from '@chakra-ui/react';
import { strings } from '@localisation';
import { Routes } from '@routes';
import { useHistory } from 'react-router-dom';

export const InvitationExpired = (): JSX.Element => {
  const history = useHistory();
  return (
    <Flex minHeight="100vh" justify="center" align="center" width="100%">
      <Col
        maxWidth="22.5rem"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="6px"
        p="1.5rem"
        alignItems="center"
        textAlign="center"
      >
        <LightText text={strings.invitation.expiredTitle} fontSize="1.75rem" />
        <NormalText
          text={strings.invitation.expiredDescription}
          fontSize="0.875rem"
          color="gray.600"
        />
        <NotFound />
        <Button colorScheme="teal" onClick={() => history.push(Routes.Home)}>
          {strings.invitation.expiredCta}
        </Button>
      </Col>
    </Flex>
  );
};
