import { Box, Link } from '@chakra-ui/react';
import { SignInSettingsOutput } from '@generated/graphql';
import { strings } from '@localisation';

type DisclaimerType = {
  links?: SignInSettingsOutput;
};

export const Disclaimer = ({ links }: DisclaimerType): JSX.Element => {
  return (
    <Box fontSize="0.75rem" color="gray.600" mt={3} px={{ base: 2, md: 0 }}>
      <Link
        color="teal.600"
        fontWeight="500"
        isExternal
        href="https://www.vintrace.com/privacy-policy/"
        display="inline"
      >
        {strings.disclaimer.privacyPolicy}
      </Link>
      ,{' '}
      <Link
        color="teal.600"
        fontWeight="500"
        isExternal
        href="https://www.vintrace.com/terms-and-conditions/"
      >
        {strings.disclaimer.termsOfService}
      </Link>
      {links && (
        <>
          ,{' '}
          <Link color="teal.600" fontWeight="500" href={links?.google.url}>
            {strings.disclaimer.agentLogin}
          </Link>
        </>
      )}
    </Box>
  );
};
