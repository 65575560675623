import { useEffect } from 'react';
import {
  Label,
  TextInput,
  PasswordInput,
  Col,
  useQueryString,
  errorsContainsOneOf,
} from '@carafe/components';
import { useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { Form } from '@components/account/Form';
import { FormHeader, FormLogo } from '@components/forms';
import { strings } from '@localisation';
import { Centered } from '@layout';
import { useVintraceLoginMutation } from '@generated/graphql';
import { Disclaimer } from '@components/account';
import { errorCodeParser, messageForCodes } from '@errors';
import { CentralAuthErrorCode } from '@carafe/errors';

type FormData = {
  username: string;
  password: string;
  endOtherSessions: boolean;
};
const signInErrors = [
  CentralAuthErrorCode.INVALID_CREDENTIALS,
  CentralAuthErrorCode.VINTRACE_SERVER_ERROR,
];
const useMobileAppLogin = () => {
  const [customerCode, redirectUri] = useQueryString([
    'customerCode',
    'redirectUri',
  ]);

  const [{ data, fetching, error }, vintraceLogin] = useVintraceLoginMutation();
  // If things go so wrong we don't know why and are unable to recover the user
  // is sent back to the mobile app.
  useEffect(() => {
    if (data?.vintraceLogin) {
      const { apiUrl, token, customerCode } = data.vintraceLogin;
      window.location.href = `${redirectUri}?token=${token}&apiUrl=${apiUrl}&customerCode=${customerCode}`;
      return;
    }
  }, [data?.vintraceLogin, redirectUri]);

  const errors = errorCodeParser(error?.graphQLErrors);

  const signInFailure = errorsContainsOneOf(errors, signInErrors);
  const conflictFailure = errorsContainsOneOf(errors, [
    CentralAuthErrorCode.CONCURRENT_USER_ACTIVE,
  ]);

  useEffect(() => {
    if (errors.length && !signInFailure && !conflictFailure) {
      window.location.href = `${redirectUri}?requiresLegacyLogin=true&customerCode=${customerCode}`;
    }
  }, [conflictFailure, customerCode, errors, redirectUri, signInFailure]);

  return {
    customerCode,
    conflictFailure,
    vintraceLogin,
    fetching,
    signInFailure,
    errors,
  };
};

/**
 * This component is intended only for use with the mobile app and, objectively,
 * navigating to the route it's mounted at should be difficult.
 */
export const VintraceLogin = (): JSX.Element => {
  const { register, handleSubmit, setValue } = useForm<FormData>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    conflictFailure,
    customerCode,
    vintraceLogin,
    fetching,
    signInFailure,
    errors,
  } = useMobileAppLogin();

  useEffect(() => {
    if (conflictFailure) {
      onOpen();
    }
  }, [conflictFailure, onOpen]);

  const onCancel = () => {
    setValue('password', '');
    setValue('username', '');
    onClose();
  };

  const onSubmit = ({ username, password, endOtherSessions }: FormData) => {
    if (!customerCode) {
      return;
    }
    vintraceLogin({
      data: {
        customerCode,
        username: username.trim(),
        password: password.trim(),
        endOtherSessions,
      },
    });
  };

  return (
    <>
      <Centered>
        <Col alignItems="center" maxWidth="21rem">
          <FormLogo />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormHeader
              title={strings.vintraceLogin.title}
              description={strings.vintraceLogin.description}
            />
            <Checkbox ref={register} display="none" name="endOtherSessions" />
            <FormControl mt="2rem" px="1rem" isInvalid={signInFailure}>
              <Label htmlFor="username">{strings.vintraceLogin.username}</Label>
              <TextInput
                id="username"
                name="username"
                autoCapitalize="off"
                ref={register({ required: true })}
                placeholder={strings.vintraceLogin.usernamePlaceholder}
              />
            </FormControl>
            <FormControl
              mt="2rem"
              px="1rem"
              mb="3rem"
              isInvalid={signInFailure}
            >
              <Label htmlFor="password">{strings.vintraceLogin.password}</Label>
              <PasswordInput
                id="password"
                name="password"
                ref={register({ required: true })}
                placeholder={strings.vintraceLogin.passwordPlaceholder}
              />
              <FormErrorMessage fontSize="0.75rem">
                {messageForCodes(signInErrors, errors)}
              </FormErrorMessage>
            </FormControl>
            <FormControl px="1rem">
              <Button
                colorScheme="teal"
                background="teal.600"
                color="white"
                width="100%"
                type="submit"
                shadow="sm"
                isLoading={fetching}
                _hover={{ background: 'teal.500' }}
              >
                {strings.vintraceLogin.button}
              </Button>
            </FormControl>
          </Form>
          <Disclaimer />
        </Col>
      </Centered>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="500" fontSize="1rem">
            {strings.endOtherSessions.title}
          </ModalHeader>
          <ModalBody fontSize="0.875rem">
            {strings.endOtherSessions.description}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button colorScheme="teal" variant="ghost" onClick={onCancel}>
              {strings.endOtherSessions.cancel}
            </Button>
            <Button
              _hover={{ backgroundColor: 'teal.500' }}
              colorScheme="teal"
              backgroundColor="teal.600"
              onClick={() => {
                setValue('endOtherSessions', true);
                handleSubmit(onSubmit)();
                onClose();
              }}
            >
              {strings.endOtherSessions.continue}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
