import { Flex } from '@chakra-ui/react';
import { IoKeyOutline } from 'react-icons/io5';
import { strings } from '@localisation';
import { AuthSpinner, useQueryString } from '@carafe/components';
import { useHandleGoogleSignInMutation } from '@generated/graphql';
import {
  useSignInWithErrorRedirect,
  useSignInWithSuccessRedirect,
} from '@hooks';
import { siwgErrorArray } from '@carafe/errors';
import React, { useEffect } from 'react';

import { InvitationAcceptedSignIn } from '@components/invitations';

export const GoogleCallback = (): JSX.Element => {
  const [code, state] = useQueryString(['code', 'state']);
  const [
    { data: googleSignIn, error: googleSignInError /*, fetching, error */ },
    handleGoogleSignIn,
  ] = useHandleGoogleSignInMutation();

  useSignInWithErrorRedirect({
    codes: siwgErrorArray,
    gqlErrors: googleSignInError?.graphQLErrors,
  });

  const { isOpen } = useSignInWithSuccessRedirect(
    googleSignIn?.handleGoogleSignIn,
  );

  useEffect(() => {
    if (code && state && !googleSignIn) {
      handleGoogleSignIn({ code, state });
    }
  }, [code, handleGoogleSignIn, state, googleSignIn]);

  return (
    <Flex
      flexDir="column"
      minHeight="100vh"
      justify="center"
      align="center"
      width="100%"
      px={{ base: '1rem', md: 'unset' }}
      textAlign="center"
    >
      <IoKeyOutline size="1.5rem" />

      <AuthSpinner
        title={strings.access.title}
        description={strings.access.description}
      />
      <InvitationAcceptedSignIn isOpen={isOpen} />
    </Flex>
  );
};
