import { Button, Icon, Link } from '@chakra-ui/react';
import { CarafeOktaAuthReact } from '@carafe/okta-react';
import { NormalText } from '@carafe/components';
import { SiOkta } from 'react-icons/si';
import { strings } from '@localisation';

interface OktaSignInButtonProps {
  oktaAuthOptions: {
    issuer?: string;
    clientId?: string;
  };
}

export const OktaSignInButton = ({
  oktaAuthOptions,
}: OktaSignInButtonProps): JSX.Element => {
  const onClick = async () => {
    return new CarafeOktaAuthReact(oktaAuthOptions).handleOktaRedirect();
  };

  return (
    <Button
      width="100%"
      border="1px solid"
      borderColor="gray.300"
      colorScheme="white"
      color="gray.900"
      fontSize="0.75rem"
      onClick={onClick}
      as={Link}
    >
      <Icon as={SiOkta} w={5} h={5} color={'blue.550'} />
      <NormalText ml="0.375rem" text={strings.social.okta} />
    </Button>
  );
};
