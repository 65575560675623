import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { IoKeyOutline } from 'react-icons/io5';

import { strings } from '@localisation';

import { AuthSpinner } from '@carafe/components';
import {
  useSignInWithErrorRedirect,
  useSignInWithSuccessRedirect,
} from '@hooks';
import { useOktaSignInMutation } from '@generated/graphql';
import { siwoErrorArray, SiwoErrorCode } from '@carafe/errors';

import { InvitationAcceptedSignIn } from '@components/invitations';
import { CarafeOktaAuthReact } from '@carafe/okta-react';
import { GraphQLError } from 'graphql';

export const OktaCallback = (): JSX.Element => {
  const [{ data, error }, exec] = useOktaSignInMutation();
  const [parsingError, setParsingError] = useState<GraphQLError[]>([]);

  const { isOpen } = useSignInWithSuccessRedirect(data?.handleOktaSignIn);

  useSignInWithErrorRedirect({
    codes: siwoErrorArray,
    gqlErrors: error?.graphQLErrors ?? parsingError,
  });

  useEffect(() => {
    const performRedirect = async () => {
      // This is issuer url fake, but it's allowed to be for some reason.
      // To get the real details, we'd need to create a new end point to get
      //  the social sign in links via the encoded authState returned from okta.
      // I believe that would over-complicate this section of the application, because it works with a fake url.
      // Who knows why okta did it this way. Still fetches the tokens and state just fine.
      const oktaAuth = new CarafeOktaAuthReact({
        issuer: 'https://yourOktaDomain.com/oauth2/12345678',
      });

      try {
        const { tokens, state } = await oktaAuth.parseTokensFromUrl();
        if (tokens && state) {
          await exec({ tokens: JSON.stringify(tokens), state });
        }
      } catch (e) {
        console.error(e);
        setParsingError([
          new GraphQLError(
            SiwoErrorCode.OKTA_AUTH_FAILED,
            undefined,
            undefined,
            undefined,
            undefined,
            e as Error,
            {
              errorCodes: [SiwoErrorCode.OKTA_AUTH_FAILED],
            },
          ),
        ]);
      }
    };
    performRedirect();
  }, []);

  return (
    <Flex
      flexDir="column"
      minHeight="100vh"
      justify="center"
      align="center"
      width="100%"
      px={{ base: '1rem', md: 'unset' }}
      textAlign="center"
    >
      <IoKeyOutline size="1.5rem" />

      <AuthSpinner
        title={strings.access.title}
        description={strings.access.description}
      />

      <InvitationAcceptedSignIn isOpen={isOpen} />
    </Flex>
  );
};
