import { Button } from '@chakra-ui/react';
import { IoArrowBackOutline, IoWarningOutline } from 'react-icons/io5';
import { Col, LightText, useQueryString } from '@carafe/components';
import { SsoErrorCode } from '@errors';
import { Centered } from '@layout';
import { strings } from '@localisation';
import { useHistory } from 'react-router-dom';
import { Routes } from '@routes';

const parseError = (errorId: string | null) => {
  if (errorId !== null && errorId in SsoErrorCode) {
    return errorId as SsoErrorCode;
  }
  return SsoErrorCode.SSO_INVALID_REQUEST;
};

export const SsoAuthError = (): JSX.Element => {
  const [errorId] = useQueryString(['errorId']);
  const error = parseError(errorId);
  const message = strings.error[error];
  const history = useHistory();

  return (
    <Centered>
      <Col alignItems="center">
        <IoWarningOutline size="2rem" />
        <LightText
          py="1rem"
          textAlign="center"
          maxWidth={{ base: '95%', md: '30rem' }}
          text={message}
          fontSize="1.75rem"
        />
        <Button
          colorScheme="teal"
          onClick={() => history.push(Routes.Home + window.location.search)}
        >
          <IoArrowBackOutline size="1.25rem" />
          {strings.access.goBack}
        </Button>
      </Col>
    </Centered>
  );
};
