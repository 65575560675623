import { Button, Icon, Link } from '@chakra-ui/react';
import { NormalText } from '@carafe/components';
import { FcGoogle } from 'react-icons/fc';
import { strings } from '@localisation';

interface Props {
  url: string;
}

export const GoogleSignInButton = ({ url }: Props): JSX.Element => {
  return (
    <Button
      width="100%"
      border="1px solid"
      borderColor="gray.300"
      colorScheme="white"
      color="gray.900"
      fontSize="0.75rem"
      as={Link}
      href={url}
      isDisabled={!url.length}
    >
      <Icon as={FcGoogle} w={5} h={5} />
      <NormalText ml={2} text={strings.social.google} />
    </Button>
  );
};
