import { OktaAuth } from '@okta/okta-auth-js';
import type {
  OktaAuthOptions,
  TokenResponse,
} from '@okta/okta-auth-js/lib/types';

export class CarafeOktaAuthReact {
  oktaAuth: OktaAuth;

  constructor(oktaAuthOptions: OktaAuthOptions) {
    this.oktaAuth = new OktaAuth(oktaAuthOptions);
  }

  public handleOktaRedirect = async (
    oktaAuth: OktaAuth = this.oktaAuth,
  ): Promise<void> => {
    return oktaAuth.token.getWithRedirect({
      responseType: 'token',
    });
  };

  public parseTokensFromUrl = (
    oktaAuth: OktaAuth = this.oktaAuth,
  ): Promise<TokenResponse> => {
    return oktaAuth.token.parseFromUrl();
  };
}
