import React from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { Celebration, Col, NormalText } from '@carafe/components';
import { strings } from '@localisation';

const { invitation } = strings;

export const InvitationAcceptedSignIn = ({
  isOpen,
}: {
  isOpen: boolean;
}): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={() => null} isCentered>
      <ModalOverlay />
      <ModalContent py="1.5rem" maxWidth="23rem">
        <Col
          alignItems="center"
          justifyContent="center"
          maxWidth="20rem"
          mx="auto"
          textAlign="center"
        >
          <NormalText text={invitation.title} fontSize="1.75rem" />
          <NormalText
            text={invitation.description}
            fontSize="0.875rem"
            color="gray.600"
            mb="1rem"
          />
          <Celebration />
        </Col>
      </ModalContent>
    </Modal>
  );
};
