import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
  Stack,
} from '@chakra-ui/react';
import { VintraceSingleMono } from '@carafe/components';
import { Disclaimer, SignInForm, SignUpForm } from '@components/account';
import { useAccount } from '@account';
import { InvitationAccepted } from '@components/invitations';
import { strings } from '@localisation';
import Marketing from '@components/account/Marketing';
import { useSocialLoginUrls } from '@hooks';

export const SignInSignUp = (): JSX.Element => {
  const { links } = useSocialLoginUrls();
  const { tabIndex, onTabChange } = useAccount();
  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      minHeight="100vh"
      justify="center"
      align="center"
      width="100%"
    >
      <Flex flex={1} flexDir="column" alignItems="center">
        <Box mb="1.5rem" mt={{ base: '1.5rem', md: 'none' }}>
          <VintraceSingleMono scale={1.5} fill="#00ACA7" />
        </Box>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          width="100%"
          justifyContent="center"
          minHeight="32rem"
          flex={1}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            width="100%"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Tabs
              display="flex"
              flexDirection="column"
              maxWidth="351px"
              height="100%"
              width="100%"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="1.25rem"
              isFitted
              isLazy
              index={tabIndex}
              onChange={onTabChange}
            >
              <TabList>
                <Tab
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="gray.600"
                  borderTopLeftRadius="1.25rem"
                  _selected={{ color: 'black', borderColor: 'teal.600' }}
                >
                  {strings.signIn.button}
                </Tab>
                <Tab
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="gray.600"
                  borderTopRightRadius="1.25rem"
                  _selected={{ color: 'black', borderColor: 'teal.600' }}
                >
                  {strings.signUp.button}
                </Tab>
              </TabList>
              <TabPanels height="100%">
                <TabPanel
                  px="1.5rem"
                  pt="1.5rem"
                  height="100%"
                  display={'flex'}
                  flexDir={'column'}
                  justifyContent={'center'}
                >
                  <SignInForm />
                </TabPanel>
                <TabPanel
                  px="1.5rem"
                  pt="1.5rem"
                  height="100%"
                  display={'flex'}
                  flexDir={'column'}
                  justifyContent={'center'}
                >
                  <SignUpForm />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Marketing />
          </Stack>
        </Flex>
        <Disclaimer links={links} />
      </Flex>
      <InvitationAccepted />
    </Flex>
  );
};
