/** Plan codes for subscriptions in Invoiced */
export enum PlanCode {
  /** Boutique - Monthly */
  VT_BOU_MNTH = 'VT_BOU_MNTH',

  /** Boutique - Yearly */
  VT_BOU_YEAR = 'VT_BOU_YEAR',

  /** Small Estate - Monthly */
  VT_ESTS_MNTH = 'VT_ESTS_MNTH',

  /** Small Estate - Yearly */
  VT_ESTS_YEAR = 'VT_ESTS_YEAR',

  /** Estate - Monthly */
  VT_EST_MNTH = 'VT_EST_MNTH',

  /** Estate - Yearly */
  VT_EST_YEAR = 'VT_EST_YEAR',

  /** Custom Crush - Monthly */
  VT_CUS_MNTH = 'VT_CUS_MNTH',

  /** Custom Crush - Yearly */
  VT_CUS_YEAR = 'VT_CUS_YEAR',

  /** Enterprise - Monthly */
  VT_ENT_MNTH = 'VT_ENT_MNTH',

  /** Enterprise - Yearly */
  VT_ENT_YEAR = 'VT_ENT_YEAR',
}
