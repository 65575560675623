import React, { useState } from 'react';
import {
  EmailInput,
  Label,
  PasswordInput,
  Col,
  errorsContainsOneOf,
  useQueryString,
} from '@carafe/components';
import { useResetPasswordMutation } from '@generated/graphql';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormErrorMessage,
  useToast,
  Link,
  ToastPosition,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Form } from '@components/account/Form';
import { strings } from '@localisation';
import { useHistory, Link as RRLink } from 'react-router-dom';
import { Routes } from '@routes';
import { errorCodeParser, messageForCodes } from '@errors';
import { CentralAuthErrorCode } from '@carafe/errors';
import { Centered } from '@layout';
import { FormHeader, FormLogo } from '@components/forms';

type FormData = {
  email: string;
  code: string;
  password: string;
};

const passwordErrorCodes = [
  CentralAuthErrorCode.PASSWORD_TOO_LONG,
  CentralAuthErrorCode.PASSWORD_TOO_SHORT,
];

const emailErrorCodes = [
  CentralAuthErrorCode.INVALID_EMAIL,
  CentralAuthErrorCode.USER_NOT_FOUND,
];

export const ResetPassword = (): JSX.Element => {
  const history = useHistory();

  const [{ fetching }, resetPasswordMutation] = useResetPasswordMutation();

  const toast = useToast();
  const toastPosition: ToastPosition | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'top-right',
  });

  const [code, email] = useQueryString(['code', 'email']);

  const {
    register,
    handleSubmit,
    errors: formErrors,
    clearErrors,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      code: code || undefined,
      email: email || undefined,
    },
  });

  const [parsedErrors, setParsedErrors] = useState<CentralAuthErrorCode[]>([]);

  const successToast = () =>
    toast({
      title: strings.form.resetPasswordForm.title,
      description: strings.form.resetPasswordForm.toastDescription,
      status: 'success',
      position: toastPosition,
      onCloseComplete: () => {
        history.push(Routes.Home);
      },
    });

  const errorToast = () =>
    toast({
      title: strings.form.resetPasswordForm.error.toastTitle,
      description: strings.form.resetPasswordForm.error.toastDescription,
      status: 'error',
      position: toastPosition,
    });

  const onSubmit = ({ email, password }: FormData) => {
    if (code) {
      clearErrors();
      resetPasswordMutation({ data: { email, code, password } })
        .then(({ error }) => {
          if (error) {
            // TODO: Parse these errors for more relevant toast feedback
            errorToast();
            setParsedErrors(errorCodeParser(error.graphQLErrors));
            // TODO - Datadog log
            console.error('ResetPassword (urql):', error.graphQLErrors);
            return;
          }
          successToast();
          reset();
        })
        .catch((error) => {
          // TODO - Datadog log
          console.error('ResetPassword (catch):', { error });
        });
    }
  };

  return (
    <Centered>
      <Col alignItems="center">
        <FormLogo />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormHeader title={strings.form.resetPasswordForm.title} />

          <FormControl
            px="1rem"
            mb=".75rem"
            isInvalid={
              !!formErrors.email ||
              errorsContainsOneOf(parsedErrors, emailErrorCodes)
            }
          >
            <Label isRequired htmlFor="email">
              {strings.form.emailLabel}
            </Label>
            <EmailInput
              id="email"
              name="email"
              placeholder={strings.form.emailPlaceholder}
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: strings.form.emailValidation,
                },
              })}
            />
            <FormErrorMessage>
              {messageForCodes(parsedErrors, emailErrorCodes) ??
                strings.form.emailValidation}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            px="1rem"
            mt="1rem"
            mb="3rem"
            isInvalid={
              !!formErrors.password ||
              errorsContainsOneOf(parsedErrors, passwordErrorCodes)
            }
          >
            <Label isRequired htmlFor="password">
              {strings.signUp.passwordLabel}
            </Label>
            <PasswordInput
              id="password"
              name="password"
              ref={register({
                required: strings.form.passwordRequired,
              })}
              placeholder={strings.signUp.passwordPlaceholder}
            />
            <FormErrorMessage>
              {messageForCodes(parsedErrors, passwordErrorCodes) ??
                strings.form.passwordRequired}
            </FormErrorMessage>
          </FormControl>
          <FormControl px="1rem">
            <Button
              backgroundColor="teal.600"
              color="white"
              width="100%"
              type="submit"
              shadow="sm"
              isLoading={fetching}
            >
              {strings.form.submit}
            </Button>
          </FormControl>
          <FormControl
            py="1rem"
            px="1rem"
            justifyContent="center"
            display="flex"
          >
            <Link
              as={RRLink}
              to={Routes.Home}
              fontWeight="500"
              fontSize="0.875rem"
            >
              {strings.form.returnToLogin}
            </Link>
          </FormControl>
        </Form>
      </Col>
    </Centered>
  );
};
