import {
  CentralAuthErrorCode,
  SiwaErrorCode,
  SiwgErrorCode,
  SiwmErrorCode,
} from '@carafe/errors';
import { SsoErrorCode } from '@errors';
import LocalizedStrings from 'react-localization';
import en from './en.json';
import es from './es.json';
import it from './it.json';

export type ErrorCodeKeys =
  | keyof typeof CentralAuthErrorCode
  | keyof typeof SiwaErrorCode
  | keyof typeof SiwgErrorCode
  | keyof typeof SiwmErrorCode
  | keyof typeof SsoErrorCode;

export type ErrorStrings = {
  error: Record<ErrorCodeKeys, string>;
};

export type StringStructure = Omit<typeof en, 'error'> & ErrorStrings;

declare const NODE_ENV: string;

/**
 * This is here so that should we forget to include any of the error strings in
 * our primary language file, TypeScript will bark at us very loudly.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _checkEn: StringStructure = en;

export const strings = new LocalizedStrings(
  {
    en,
    es: es as unknown as typeof en,
    it: it as unknown as typeof en,
  },
  {
    logsEnabled: process.env.NODE_ENV === 'development',
  },
);
