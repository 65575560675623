import { VintracePermissionCode } from './VintracePermissionCode';
import { VintraceRoleCode } from './VintraceRoleCode';
import { VintraceUserRoleCode } from './VintraceUserRoleCode';

export interface UserRoleRoleMapItem {
  permissions: VintracePermissionCode[];
  roles: VintraceRoleCode[];
}

export type UserRoleMap = {
  [key in keyof typeof VintraceUserRoleCode]: UserRoleRoleMapItem;
};

export const administratorPermissions = [
  VintracePermissionCode.SUBSCRIPTION_ADMIN,
  VintracePermissionCode.CAN_ADD_EDIT_GROWERS_VINEYARDS_AND_BLOCKS,
  VintracePermissionCode.CAN_ADD_EDIT_TRIAL_BLENDS,
  VintracePermissionCode.CAN_CHANGE_WINE_OWNERSHIP,
  VintracePermissionCode.CAN_MANAGE_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_CREATE_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_VIEW_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_ADD_EDIT_VESSELS,
];
export const cellarhandPermissions = [
  VintracePermissionCode.CAN_SUBMIT_OPERATIONS,
  VintracePermissionCode.CAN_RESURRECT,
  VintracePermissionCode.CAN_SUBMIT_MRP_ACTIONS,
  VintracePermissionCode.CAN_VIEW_PRODUCT_NOTES,
  VintracePermissionCode.CAN_ADD_PRODUCT_NOTES,
  VintracePermissionCode.CAN_EDIT_EXISTING_PRODUCT_NOTES,
  VintracePermissionCode.CAN_REASSIGN_APP_JOBS,
  VintracePermissionCode.CAN_CHANGE_WINE_OWNERSHIP,
];

export const reportingPermissions = [
  VintracePermissionCode.CAN_VIEW_COSTS,
  VintracePermissionCode.CAN_ADJUST_COSTS,
  VintracePermissionCode.CAN_MANAGE_CLIENT_BILLING,
  VintracePermissionCode.CAN_VIEW_PRODUCT_NOTES,
  VintracePermissionCode.CAN_ADD_PRODUCT_NOTES,
  VintracePermissionCode.CAN_EDIT_EXISTING_PRODUCT_NOTES,
  VintracePermissionCode.CAN_MANAGE_SALES_ORDER,
  VintracePermissionCode.CAN_MANAGE_PURCHASE_ORDER,
  VintracePermissionCode.CAN_MANAGE_GROWER_CONTRACT,
  VintracePermissionCode.CAN_ADD_EDIT_GROWERS_VINEYARDS_AND_BLOCKS,
  VintracePermissionCode.CAN_MANAGE_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_VIEW_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_ADD_EDIT_VESSELS,
  VintracePermissionCode.CAN_ADD_COSTS_ON_RECEIVAL,
];
export const winemakerPermissions = [
  VintracePermissionCode.CAN_SCHEDULE_TASKS,
  VintracePermissionCode.CAN_SUBMIT_OPERATIONS,
  VintracePermissionCode.CAN_RESURRECT,
  VintracePermissionCode.CAN_COMPLETE_TASKS_OUT_OF_SEQUENCE,
  VintracePermissionCode.CAN_FUTURE_DATE_OPERATIONS,
  VintracePermissionCode.CAN_RECORD_LARGE_LOSS,
  VintracePermissionCode.CAN_FORCE_ENTITY_UNLOCK,
  VintracePermissionCode.CAN_MOVE_WINE_BETWEEN_BONDS,
  VintracePermissionCode.CAN_SUBMIT_MRP_ACTIONS,
  VintracePermissionCode.CAN_MOVE_WINE_BETWEEN_WINERIES,
  VintracePermissionCode.CAN_ADJUST_COSTS,
  VintracePermissionCode.CAN_PERFORM_POWER_USER_OPERATIONS,
  VintracePermissionCode.CAN_MANAGE_CLIENT_BILLING,
  VintracePermissionCode.CAN_CHANGE_WINERY_DEFAULTS,
  VintracePermissionCode.CAN_VIEW_PRODUCT_NOTES,
  VintracePermissionCode.CAN_ADD_PRODUCT_NOTES,
  VintracePermissionCode.CAN_EDIT_EXISTING_PRODUCT_NOTES,
  VintracePermissionCode.CAN_MANAGE_SALES_ORDER,
  VintracePermissionCode.CAN_MANAGE_PURCHASE_ORDER,
  VintracePermissionCode.CAN_REASSIGN_APP_JOBS,
  VintracePermissionCode.CAN_MANAGE_GROWER_CONTRACT,
  VintracePermissionCode.CAN_ADD_EDIT_GROWERS_VINEYARDS_AND_BLOCKS,
  VintracePermissionCode.CAN_ADD_EDIT_TRIAL_BLENDS,
  VintracePermissionCode.CAN_CHANGE_WINE_OWNERSHIP,
  VintracePermissionCode.CAN_MANAGE_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_VIEW_PRODUCT_ALLOCATIONS,
  VintracePermissionCode.CAN_ADD_EDIT_TRIAL_BLENDS,
  VintracePermissionCode.ADVANCED_DATA_ENTRY,
  VintracePermissionCode.LAB_DATA_ADMIN,
  VintracePermissionCode.MODIFY_VESSEL_ALERT_STATE,
  VintracePermissionCode.IMPORT_AND_EXPORT_SETUP_DATA,
  VintracePermissionCode.CAN_ADD_EDIT_VESSELS,
  VintracePermissionCode.CAN_ADD_COSTS_ON_RECEIVAL,
];

export const permissions = Object.values(VintracePermissionCode);

export const userRoleArray = [
  {
    userRole: VintraceUserRoleCode.ADMINISTRATOR,
    permissions: administratorPermissions,
  },
  {
    userRole: VintraceUserRoleCode.CELLARHAND,
    permissions: cellarhandPermissions,
  },
  {
    userRole: VintraceUserRoleCode.REPORTING,
    permissions: reportingPermissions,
  },
  {
    userRole: VintraceUserRoleCode.WINEMAKER,
    permissions: winemakerPermissions,
  },
];

export const userRoleMap: UserRoleMap = {
  [VintraceUserRoleCode.ADMINISTRATOR]: {
    permissions: administratorPermissions,
    roles: [],
  },
  [VintraceUserRoleCode.CELLARHAND]: {
    permissions: cellarhandPermissions,
    roles: [VintraceRoleCode.LAB_TECHNICIAN, VintraceRoleCode.OPERATOR],
  },
  [VintraceUserRoleCode.REPORTING]: {
    permissions: reportingPermissions,
    roles: [],
  },
  [VintraceUserRoleCode.WINEMAKER]: {
    permissions: winemakerPermissions,
    roles: [
      VintraceRoleCode.LAB_TECHNICIAN,
      VintraceRoleCode.OPERATOR,
      VintraceRoleCode.WINE_MAKER,
    ],
  },
};
