import {
  Flex,
  FormControl,
  FormErrorMessage,
  Button,
  Center,
  Spinner,
} from '@chakra-ui/react';
import {
  PasswordInput,
  Label,
  EmailInput,
  TextInput,
  PhoneInput,
  NormalText,
  errorsContainsOneOf,
} from '@carafe/components';
import { Social } from './Social';
import { strings } from '@localisation';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { SignUpFormData, useAccount } from '@account';
import { errorCodeParser, messageForCodes } from '@errors';
import { CentralAuthErrorCode } from '@carafe/errors';
import { useSocialLoginUrls } from '@hooks';
import { Continue } from '@components/account/Continue';

const passwordErrorCodes = [
  CentralAuthErrorCode.PASSWORD_REQUIRED,
  CentralAuthErrorCode.PASSWORD_TOO_LONG,
  CentralAuthErrorCode.PASSWORD_TOO_SHORT,
];

const emailErrorCodes = [
  CentralAuthErrorCode.EMAIL_IN_USE,
  CentralAuthErrorCode.INVALID_EMAIL,
  CentralAuthErrorCode.REGISTRATION_BY_INVITE_ONLY,
];

export const SignUpForm = (): JSX.Element => {
  const { register, handleSubmit, errors, clearErrors, setValue } =
    useForm<SignUpFormData>({
      mode: 'onBlur',
      reValidateMode: 'onChange',
    });
  const { invite, onSignUp, signUpError, isLoading } = useAccount();

  const { isFetching, links } = useSocialLoginUrls();

  // set data from invitation where available.
  useEffect(() => {
    if (invite) {
      setValue('email', invite.email);
      setValue('firstName', invite.firstName ?? '');
      setValue('lastName', invite.lastName ?? '');
      setValue('phoneNumber', invite.phoneNumber ?? '');
    }
  }, [invite, setValue, links]);

  const _errors = errorCodeParser(signUpError?.graphQLErrors);

  const showOr =
    (links?.apple.enabled ||
      links?.google.enabled ||
      links?.microsoft.enabled ||
      links?.okta.enabled) &&
    links?.basic.enabled;

  return (
    <>
      {isFetching && (
        <Center height="100%">
          <Spinner
            size="lg"
            speed="0.6s"
            emptyColor="gray.300"
            color="teal.600"
          />
        </Center>
      )}
      {links && <Social links={links} />}

      {showOr && <Continue>{strings.social.continue}</Continue>}

      {links?.basic.enabled && (
        <form
          onSubmit={handleSubmit((onValid) => {
            clearErrors();
            onSignUp(onValid);
          })}
        >
          <Flex flexDir="row" justifyContent="space-between">
            <FormControl
              mb="1rem"
              maxWidth="45%"
              isInvalid={
                !!errors.firstName ||
                errorsContainsOneOf(_errors, [
                  CentralAuthErrorCode.FIRST_NAME_REQUIRED,
                ])
              }
            >
              <Label fontSize="0.75rem" htmlFor="firstName">
                {strings.form.firstNameLabel}
              </Label>
              <TextInput
                backgroundColor="gray.25"
                id="firstName"
                name="firstName"
                ref={register({ required: true })}
                placeholder="Enter name"
              />
              <FormErrorMessage fontSize="0.75rem">
                <NormalText text={strings.error.FIRST_NAME_REQUIRED} />
              </FormErrorMessage>
            </FormControl>

            <FormControl
              maxWidth="45%"
              isInvalid={
                !!errors.lastName ||
                errorsContainsOneOf(_errors, [
                  CentralAuthErrorCode.LAST_NAME_REQUIRED,
                ])
              }
            >
              <Label fontSize="0.75rem" htmlFor="lastName">
                {strings.form.lastNameLabel}
              </Label>
              <TextInput
                id="lastName"
                name="lastName"
                ref={register({ required: true })}
                placeholder="Enter name"
                backgroundColor="gray.25"
              />
              <FormErrorMessage fontSize="0.75rem">
                <NormalText text={strings.error.LAST_NAME_REQUIRED} />
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <FormControl
            mb="0.75rem"
            isInvalid={
              !!errors.email || errorsContainsOneOf(_errors, emailErrorCodes)
            }
          >
            <Label fontSize="0.75rem" htmlFor="email">
              {strings.form.emailLabel}
            </Label>
            <EmailInput
              id="email"
              name="email"
              backgroundColor="gray.25"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: strings.form.emailValidation,
                },
              })}
              placeholder={strings.form.emailPlaceholder}
            />
            <FormErrorMessage fontSize="0.75rem">
              <NormalText
                text={
                  messageForCodes(_errors, emailErrorCodes) ||
                  strings.form.emailValidation
                }
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="0.75rem"
            isInvalid={
              !!errors.password ||
              errorsContainsOneOf(_errors, passwordErrorCodes)
            }
          >
            <Label fontSize="0.75rem" htmlFor="password">
              {strings.signUp.passwordLabel}
            </Label>
            <PasswordInput
              id="password"
              name="password"
              backgroundColor="gray.25"
              ref={register({
                required: strings.form.passwordRequired,
              })}
              placeholder={strings.signUp.passwordPlaceholder}
            />
            <FormErrorMessage fontSize="0.75rem">
              <NormalText
                text={
                  messageForCodes(_errors, passwordErrorCodes) ||
                  strings.error.PASSWORD_REQUIRED
                }
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            mb="0.75rem"
            isInvalid={
              !!errors.phoneNumber ||
              errorsContainsOneOf(_errors, [
                CentralAuthErrorCode.PHONE_NUMBER_REQUIRED,
              ])
            }
          >
            <Label fontSize="0.75rem" htmlFor="phoneNumber">
              {strings.signUp.cellMobileLabel}
            </Label>
            <PhoneInput
              id="phoneNumber"
              backgroundColor="gray.25"
              name="phoneNumber"
              ref={register({
                required: strings.error.PHONE_NUMBER_REQUIRED,
              })}
              placeholder={strings.signUp.cellMobilePlaceholder}
            />
            <FormErrorMessage fontSize="0.75rem">
              <NormalText text={strings.error.PHONE_NUMBER_REQUIRED} />
            </FormErrorMessage>
          </FormControl>
          <FormControl mt="0.25rem">
            <Button
              width="100%"
              type="submit"
              colorScheme="teal"
              backgroundColor="teal.600"
              isLoading={isLoading}
            >
              {strings.signUp.button}
            </Button>
          </FormControl>
        </form>
      )}
    </>
  );
};
