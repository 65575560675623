import { useEffect, useState } from 'react';

interface MobileSsoErrorRedirect {
  clientId: string | null;
  redirectUri: string | null;
}
export const useMobileSsoErrorRedirect = (): MobileSsoErrorRedirect => {
  const [redirectUri, setRedirectUri] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);

  useEffect(() => {
    const redirectUri = localStorage.getItem('redirectUri');
    const clientId = localStorage.getItem('clientId');
    setRedirectUri(redirectUri);
    setClientId(clientId);
  }, []);

  return { clientId, redirectUri };
};
