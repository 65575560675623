import {
  EmailInput,
  Label,
  Col,
  errorsContainsOneOf,
} from '@carafe/components';
import { CentralAuthErrorCode } from '@carafe/errors';
import {
  Button,
  FormControl,
  FormErrorMessage,
  useToast,
  Link,
  useBreakpointValue,
  ToastPosition,
} from '@chakra-ui/react';
import { Form } from '@components/account/Form';
import { FormHeader, FormLogo } from '@components/forms';
import { errorCodeParser, messageForCodes } from '@errors';
import { useForgotPasswordMutation } from '@generated/graphql';
import { Centered } from '@layout';
import { strings } from '@localisation';
import { Routes } from '@routes';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RRLink, useLocation } from 'react-router-dom';

type FormData = {
  email: string;
};

const errorCodes = [CentralAuthErrorCode.USER_NOT_FOUND];

interface LocationState {
  isInitialLogin?: boolean;
  customerCode?: string | null;
  email?: string;
}

export const ForgotPassword = (): JSX.Element => {
  const toast = useToast();
  const { state } = useLocation<Partial<LocationState>>();

  const [{ data, fetching, error }, execute] = useForgotPasswordMutation();

  const toastPosition: ToastPosition | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'top-right',
  });

  const { register, handleSubmit, errors, clearErrors, reset, setValue } =
    useForm<FormData>({
      mode: 'onBlur',
    });

  // set email in form when available
  useEffect(() => {
    if (state?.email) {
      setValue('email', state.email);
    }
  }, [setValue, state?.email]);

  useEffect(() => {
    if (data?.forgotPassword) {
      toast({
        title: strings.form.forgottenPasswordForm.toastTitle,
        description: strings.form.forgottenPasswordForm.toastDescription,
        status: 'success',
        position: toastPosition,
      });
      reset();
    }
  }, [data?.forgotPassword, reset, toast, toastPosition]);

  const _errors = errorCodeParser(error?.graphQLErrors);

  const onSubmit = ({ email }: FormData) => {
    clearErrors();
    execute({
      data: {
        email,
        customerCode: state?.customerCode ?? '',
        isInitialLogin: state?.isInitialLogin ?? false,
      },
    });
  };

  return (
    <Centered>
      <Col alignItems="center" maxWidth="21rem">
        <FormLogo />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormHeader
            title={strings.form.forgottenPasswordForm.title}
            description={strings.form.forgottenPasswordForm.description}
          />

          <FormControl
            mt="2rem"
            px="1rem"
            mb="3rem"
            isInvalid={
              !!errors.email || errorsContainsOneOf(_errors, errorCodes)
            }
          >
            <Label htmlFor="email">{strings.form.emailLabel}</Label>
            <EmailInput
              id="email"
              name="email"
              placeholder="Enter email..."
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: strings.form.emailValidation,
                },
              })}
            />
            <FormErrorMessage>
              {messageForCodes(_errors, errorCodes) ??
                strings.form.emailValidation}
            </FormErrorMessage>
          </FormControl>
          <FormControl px="1rem">
            <Button
              backgroundColor="teal.600"
              color="white"
              width="100%"
              type="submit"
              shadow="sm"
              isLoading={fetching}
            >
              {strings.form.submit}
            </Button>
          </FormControl>
          <FormControl
            py="1rem"
            px="1rem"
            justifyContent="center"
            display="flex"
          >
            <Link
              as={RRLink}
              to={Routes.Home}
              fontWeight="500"
              fontSize="0.875rem"
            >
              {strings.form.returnToLogin}
            </Link>
          </FormControl>
        </Form>
      </Col>
    </Centered>
  );
};
