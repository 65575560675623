import { Provider as UrqlProvider } from 'urql';
import { WithChildren } from '@carafe/components';
import { generateClient } from '@config';
import { useMemo, useState } from 'react';
import { AuthContext } from './AuthContext';

/**
 * Auth Provider to allow access to AuthContext and its state in child
 * components.
 *
 * Currently used for;
 * - protecting routes that require authentication
 * - reinstantiating the Urql client when we have a token to use
 * - hiding navigation links
 */
export const AuthProvider = ({ children }: WithChildren): JSX.Element => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const [token, _setToken] = useState('');

  const setToken = (token: string): void => {
    _setToken(token);
    setIsSignedIn(token.length >= 1);
  };

  // Create a memoised instance of the Urql client, so that when we have a token
  // to use, we can create a new instance with that. This saves using the urql
  // authExhcange which is outrageous and would introduce 100+ lines of hard to
  // read code.
  const client = useMemo(() => {
    return generateClient(token);
  }, [token]);

  return (
    <AuthContext.Provider value={{ isSignedIn, setToken }}>
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </AuthContext.Provider>
  );
};
