import { Button, FormControl } from '@chakra-ui/react';
import React from 'react';

interface FormSubmitPrimaryProps {
  isLoading: boolean;
  text: string;
}

export const FormSubmitPrimary = ({
  isLoading,
  text,
}: FormSubmitPrimaryProps): JSX.Element => (
  <FormControl>
    <Button
      colorScheme="teal"
      background="teal.600"
      color="white"
      width="100%"
      type="submit"
      shadow="sm"
      isLoading={isLoading}
      _hover={{ background: 'teal.500' }}
    >
      {text}
    </Button>
  </FormControl>
);
