import { forwardRef } from 'react';
import { Input, InputGroup } from '@chakra-ui/react';
import type { InputProps } from './InputTypes';
import { inputBaseStyle } from './inputBaseStyle';

type PhoneInputProps = InputProps & { showFlag?: boolean };

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ showFlag, ...rest }, ref) => (
    <InputGroup>
      <Input
        {...rest}
        ref={ref}
        type="text"
        width="100%"
        {...inputBaseStyle}
        // paddingLeft="10px"
      />
    </InputGroup>
  ),
);
