import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { SignInDataFragment } from '@generated/graphql';
import { useAuth } from '@auth';
import { Routes } from '@routes';
import { useHistory } from 'react-router-dom';

const goUrlBase = process.env.GO_VINTRACE_URL;
const sandboxUrlBase = process.env.SANDBOX_VINTRACE_URL;

interface UseSignInWithSuccessRedirect {
  isOpen: boolean;
}

/**
 * Handle user sign in success redirection. Accounts for invitation being
 * accepted, returns isOpen for use in modal.
 * @param data response from user sign in
 * @returns
 */
export const useSignInWithSuccessRedirect = (
  data?: SignInDataFragment,
): UseSignInWithSuccessRedirect => {
  const { setToken } = useAuth();
  const history = useHistory();
  const { isOpen, onOpen } = useDisclosure();

  useEffect(() => {
    async function handleInvitationAccepted(data: SignInDataFragment) {
      onOpen();
      await timer(2500);
      handleRedirect(data);
    }
    async function handleRedirect(data: SignInDataFragment) {
      const { customerCode } = data;
      // All three of these properties indicate a mobile app sign in.
      const { redirectUri, token, apiUrl } = data;
      if (redirectUri && token && apiUrl && customerCode) {
        window.location.href = `${redirectUri}?token=${token}&apiUrl=${apiUrl}&customerCode=${customerCode}`;
        return;
      }

      // If we have a customerCode and tokenId, we ship the customer off to that
      // lcoation, only adding a returnTo when available.
      const { tokenId, returnTo, sandboxManagement } = data;
      if (tokenId && customerCode) {
        let goingTo: URL;
        if (sandboxManagement) {
          goingTo = new URL(`${sandboxUrlBase}`);
          goingTo.pathname = `manage/${customerCode}/sso`;
        } else {
          goingTo = new URL(`${goUrlBase}`);
          goingTo.pathname = `${customerCode}/sso`;
        }

        goingTo.searchParams.append(`code`, tokenId);

        // Only adding a returnTo when available.
        if (returnTo) {
          goingTo.searchParams.append(`returnTo`, returnTo);
        }

        window.location.href = goingTo.toString();
        return;
      }
      // Failing the above we set the token and send the user to the dashboard.
      setToken(data.token);
      history.push(Routes.Dashboard);

      const goingTo = `${goUrlBase}/${customerCode}/sso?code=${tokenId}`;
      window.location.href = goingTo;
    }
    if (data && data.invitationAccepted) {
      handleInvitationAccepted(data);
    } else if (data) {
      handleRedirect(data);
    }
  }, [data, history, onOpen, setToken]);
  return { isOpen };
};

async function timer(msec: number) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
