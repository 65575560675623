export const NotFound = (): JSX.Element => {
  return (
    <svg
      width="181"
      height="181"
      viewBox="0 0 181 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.2417 148.735C115.829 148.735 136.571 143.521 136.571 137.088L114.646 63.7101C114.646 60.7631 103.72 58.374 90.2417 58.374C76.7634 58.374 65.8371 60.7631 65.8371 63.7101L43.9121 137.088C43.9121 143.521 64.6546 148.735 90.2417 148.735Z"
        fill="url(#paint0_linear)"
        stroke="#319795"
        strokeDasharray="2 2"
      />
      <circle
        cx="90.4999"
        cy="103.735"
        r="16.7206"
        fill="url(#paint1_linear)"
        stroke="#2C7A7B"
        strokeWidth="1.5"
      />
      <path
        d="M95.9596 98.6391C95.7813 101.045 93.9578 103.007 91.956 103.007C89.9541 103.007 88.1274 101.045 87.9523 98.6391C87.7703 96.1368 89.5447 94.2715 91.956 94.2715C94.3673 94.2715 96.1416 96.1823 95.9596 98.6391Z"
        stroke="#2C7A7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.9565 105.919C87.9983 105.919 83.981 108.103 83.2376 112.225C83.1466 112.722 83.4291 113.198 83.9496 113.198H99.9638C100.484 113.198 100.765 112.722 100.676 112.225C99.932 108.103 95.9147 105.919 91.9565 105.919Z"
        stroke="#2C7A7B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M82.8574 100.095V105.19"
        stroke="#2C7A7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.4042 102.643H80.3086"
        stroke="#2C7A7B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.907 71.8534C129.712 69.6214 138.148 65.8121 138.148 61.488C138.148 54.6115 116.815 49.0371 90.5006 49.0371C64.1858 49.0371 42.8535 54.6115 42.8535 61.488C42.8535 65.7639 51.1022 69.5365 63.6682 71.7782"
        stroke="#319795"
        strokeWidth="2"
      />
      <path
        d="M77.7684 68.4648C70.5473 67.5381 65.6992 65.8226 65.6992 63.8595C65.6992 60.9125 76.6255 58.5234 90.1038 58.5234C103.582 58.5234 114.508 60.9125 114.508 63.8595C114.508 65.8226 109.66 67.5381 102.439 68.4648"
        stroke="#319795"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.325 134.971C105.325 137.017 98.5697 138.676 90.2367 138.676C81.9037 138.676 75.1484 137.017 75.1484 134.971C75.1484 133.748 77.5567 132.665 81.2712 131.99C83.7778 131.534 86.8792 131.265 90.2367 131.265C93.5942 131.265 96.6956 131.534 99.2022 131.99C102.917 132.665 105.325 133.748 105.325 134.971Z"
        fill="url(#paint2_linear)"
        stroke="#319795"
      />
      <path
        d="M136.559 137.353C136.559 143.639 115.819 148.735 90.2356 148.735C64.6519 148.735 43.9121 143.639 43.9121 137.353C43.9121 133.6 51.306 130.271 62.7101 128.197C70.4057 126.798 79.9275 125.971 90.2356 125.971C100.544 125.971 110.066 126.798 117.761 128.197C129.165 130.271 136.559 133.6 136.559 137.353Z"
        stroke="#319795"
        strokeDasharray="2 2"
      />
      <path
        d="M106.929 49.6418C106.929 40.0447 99.5585 32.2646 90.4665 32.2646C81.3744 32.2646 74.0039 40.0447 74.0039 49.6418"
        stroke="#319795"
        strokeWidth="2"
      />
      <path
        d="M95.7945 64.0293L97.9121 83.0881"
        stroke="#319795"
        strokeWidth="1.5"
        strokeDasharray="2 2"
      />
      <line
        x1="90.7207"
        y1="62.9707"
        x2="90.7207"
        y2="83.0883"
        stroke="#319795"
        strokeWidth="1.5"
        strokeDasharray="2 2"
      />
      <path
        d="M84.6758 64.0293L82.5581 83.0881"
        stroke="#319795"
        strokeWidth="1.5"
        strokeDasharray="2 2"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="90.2417"
          y1="31.548"
          x2="161.454"
          y2="122.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="90.4999"
          y1="75.8915"
          x2="118.197"
          y2="110.513"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="90.2367"
          y1="129.064"
          x2="92.5153"
          y2="140.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
