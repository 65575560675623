import { Flex, Box } from '@chakra-ui/react';
import { WithChildren } from '@carafe/components';

export const Continue = ({ children }: WithChildren): JSX.Element => (
  <Flex flexDir="row" alignItems="center">
    <Flex borderBottom="1px solid" borderColor="gray.200" width="100%" />
    <Box
      fontSize="0.75rem"
      textAlign="center"
      color="gray.600"
      paddingY="10px"
      px={2}
      fontWeight="500"
    >
      {children}
    </Box>
    <Flex borderBottom="1px solid" borderColor="gray.200" width="100%" />
  </Flex>
);
