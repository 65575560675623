import { useEffect } from 'react';
import { errorsContainsOneOf, parseErrorCodes } from '@carafe/components';
import { useHistory } from 'react-router-dom';
import { GraphQLError } from 'graphql';
import { Routes } from '@routes';
import { CentralAuthErrorCode } from '@carafe/errors';

interface UseSignInWithErrorRedirect {
  codes: string[];
  gqlErrors?: GraphQLError[];
}

const commonSignInErrors = [
  CentralAuthErrorCode.NO_ACCESS_TO_APP,
  CentralAuthErrorCode.MOBILE_SIGN_IN_FAILED,
  CentralAuthErrorCode.INVITATION_NOT_FOUND,
  CentralAuthErrorCode.INVITATION_ALREADY_ACCEPTED,
  CentralAuthErrorCode.INVITATION_EXPIRED,
];

/**
 * Hook to handle sending the user to an error page when their sign in with *
 * fails.
 */
export const useSignInWithErrorRedirect = ({
  codes,
  gqlErrors,
}: UseSignInWithErrorRedirect): void => {
  const history = useHistory();

  useEffect(() => {
    if (!gqlErrors?.length) {
      return;
    }
    const errors = parseErrorCodes(gqlErrors);

    if (errors.length) {
      const containsKnownError =
        errorsContainsOneOf(errors, codes) ||
        errorsContainsOneOf(errors, commonSignInErrors);
      if (containsKnownError) {
        history.push(`${Routes.SignInWithError}?errorId=${errors[0]}`);
      } else {
        history.push(Routes.SsoAuthError);
      }
    }
  }, [codes, gqlErrors, history]);
};
