import { Flex } from '@chakra-ui/react';

interface Props {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  p?: string;
}

/**
 * A form abstraction, used on the SignIn and SignUp forms so they have the same
 * basic properties.
 */
export const Form = ({ children, onSubmit, p }: Props): JSX.Element => (
  <Flex
    as="form"
    flexDir="column"
    onSubmit={onSubmit}
    alignItems="center"
    minWidth="351px"
    p={p}
    pb="1rem"
    border="1px solid"
    borderColor="gray.300"
    borderRadius="1.25rem"
  >
    {children}
  </Flex>
);
