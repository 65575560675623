import { useEffect, useState } from 'react';

type OnEvent = () => void;

interface UseLongPress {
  onMouseDown: OnEvent;
  onMouseUp: OnEvent;
  onMouseLeave: OnEvent;
  onTouchStart: OnEvent;
  onTouchEnd: OnEvent;
}

/**
 * Do a thing when a user presses on something for a long time. Default wait
 * time is 300ms.
 * @param callback the function to run
 * @param ms how long to wait
 */
export const useLongPress = (callback: () => void, ms = 300): UseLongPress => {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: number | undefined;
    if (startLongPress) {
      timerId = window.setTimeout(callback, ms);
    } else {
      window.clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
};
