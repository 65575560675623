import { useQueryString } from '@carafe/components';
import { useEffect, useState } from 'react';

interface PreventEternalRedirect {
  redirects: number;
  maxAllowedRedirects: number;
  purge: () => void;
  increment: (customerCode: string) => void;
}
const MAX_ALLOWED_REDIRECTS = 4;
export const usePreventEternalRedirect = (): PreventEternalRedirect => {
  const [redirects, setRedirects] = useState(0);
  const [customerCode] = useQueryString(['customerCode']);

  useEffect(() => {
    if (customerCode) {
      const count = parseInt(localStorage.getItem(customerCode) ?? '0');
      setRedirects(count);
    }
  }, [customerCode]);

  const increment = (customerCode: string) => {
    localStorage.setItem(customerCode, `${redirects + 1}`);
  };

  const purge = () => {
    localStorage.clear();
    setRedirects(0);
  };

  return {
    increment,
    purge,
    redirects,
    maxAllowedRedirects: MAX_ALLOWED_REDIRECTS,
  };
};
