import React from 'react';
import { Row, Col, LightText } from '@carafe/components';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import {
  IoTelescopeOutline,
  IoTrophyOutline,
  IoWineOutline,
} from 'react-icons/io5';
import { strings } from '@localisation';
import { IconType } from 'react-icons';

type Flags = Record<FlagsKey, boolean>;
type FlagsKey = keyof typeof tooltip;

const idToLogo: Record<string, IconType> = {
  isLocalAdmin: IoTrophyOutline,
  allWineryAccess: IoWineOutline,
  isOwnerLogin: IoTelescopeOutline,
  lcc: IoIosCheckmarkCircleOutline,
};

const { tooltip } = strings.dashboard;

interface Props {
  customerName: string;
  flags: Flags;
  customerCode: string;
  onClick: (customerCode: string) => void;
}

/**
 * Card to display user context choices on Dashboard
 * clicking will redirect to that vintrace
 * @param codeNames text to display and flags to colour icons
 * @returns click to redirect
 */
export const VintraceAccessCard = ({
  customerName,
  flags,
  customerCode,
  onClick,
}: Props): JSX.Element => {
  return (
    <Col
      px="0.85rem"
      _hover={{
        background: 'gray.50',
        color: '#2C7A7B',
        border: '1px solid',
      }}
      as="button"
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.300"
      py="1rem"
      onClick={() => onClick(customerCode)}
      justifyContent="space-between"
      minHeight="9rem"
    >
      <Text align="left" fontSize="1.125rem" fontWeight="300">
        {customerName}
      </Text>
      <Col width="100%">
        <LightText textAlign="left" fontSize="0.75rem" text={customerCode} />
        <Row pt="0.5rem" width="100%" justifyContent="space-around">
          {Object.entries(flags).map(([k], i) => (
            <Tooltip key={i} label={tooltip[k as FlagsKey]} fontSize="md">
              <span>
                <Icon
                  _hover={{
                    color: 'gray.700',
                  }}
                  as={idToLogo[k]}
                  boxSize="1.5rem"
                  color={flags[k as FlagsKey] ? '#2C7A7B' : '#AAAAAA'}
                />
              </span>
            </Tooltip>
          ))}
        </Row>
      </Col>
    </Col>
  );
};
