import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '@routes';
import { Flex } from '@chakra-ui/react';
import { getApiBase, useQueryString } from '@carafe/components';
import { IoKeyOutline } from 'react-icons/io5';
import { strings } from '@localisation';
import { useAuth } from '@auth';
import { TokenExchange } from '@carafe/models';
import { AuthSpinner } from '@carafe/components';

export const AppleSignIn = (): JSX.Element => {
  const [code] = useQueryString(['code']);
  const history = useHistory();
  const { setToken } = useAuth();

  useEffect(() => {
    const tryAuthentication = async (code: string) => {
      try {
        const { token } = await authenticate(code);
        setToken(token);
        history.push(Routes.Dashboard);
      } catch (err) {
        history.push(Routes.SignInWithError);
      }
    };
    if (!code) {
      history.push(Routes.SignInWithError);
      return;
    }
    tryAuthentication(code);
  }, [code, setToken, history]);

  return (
    <Flex
      flexDir="column"
      minHeight="100vh"
      justify="center"
      align="center"
      width="100%"
      px={{ base: '1rem', md: 'unset' }}
      textAlign="center"
    >
      <IoKeyOutline size="1.5rem" />

      <AuthSpinner
        title={strings.access.title}
        description={strings.access.description}
      />
    </Flex>
  );
};

const authenticate = async (code: string) => {
  const apiBase = getApiBase();
  const requestUrl = `${apiBase}/authenticate?tokenId=${code}`;
  const res = await sendRequest(requestUrl);
  return res;
};

async function sendRequest(url: string, credentials?: RequestCredentials) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials,
  });

  if (res.status !== 201) {
    const err = await res.text();
    throw new Error(err);
  }

  const token = await res.json();
  return token as TokenExchange;
}
