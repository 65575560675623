import { FieldErrors } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { errorCodeParser } from '@errors/errorCodeParser';
import { messageForCodes } from '@errors/messageForCode';
import { CentralAuthErrorCode } from '@carafe/errors';

export type FormErrorMapper = {
  [key: string]: CentralAuthErrorCode[];
};

export type CombinedErrorMessages = {
  [key: string]: string;
};

/**
 * Combines errors from react-hook-forms with applicable errors from graphQL
 * returns an object in the style of { name: 'this is a name error', username: 'this is a username error' }
 * @formErrorMapper an object where the keys are the fields, matching the react-hook-forms names, and the values are a list of applicable of CentralAuthErrorCode related to that
 * @fieldErrors errors from react-hook-forms validation
 * @maybeGraphQlErrors graphql errors from urql mutations
 */
export const getErrorCombiner =
  (formErrorMapper: FormErrorMapper) =>
  (
    fieldErrors: FieldErrors,
    maybeGraphQlErrors?: GraphQLError[],
  ): CombinedErrorMessages => {
    return Object.keys(formErrorMapper)
      .map((key) => {
        const graphQlErrors = maybeGraphQlErrors ?? [];
        const centralErrorsFromGraphQl = errorCodeParser(graphQlErrors);
        const fieldError = fieldErrors[key]?.message
          ? fieldErrors[key]?.message
          : fieldErrors[key]?.type;
        return [
          key,
          fieldError ??
            messageForCodes(centralErrorsFromGraphQl, formErrorMapper[key]),
        ];
      })
      .reduce((acc, [key, record]) => {
        return {
          ...acc,
          [key]: record,
        };
      }, {});
  };
