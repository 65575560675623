import { GraphQLResolveInfo } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccountSetupInput = {
  customerCode: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AccountSetupOutput = {
  __typename?: 'AccountSetupOutput';
  customerCode: Scalars['String'];
  tokenId: Scalars['String'];
};

export type AuthStateInput = {
  code?: Maybe<Scalars['String']>;
  customerCode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  returnTo?: Maybe<Scalars['String']>;
  sandboxManagement?: Maybe<Scalars['Boolean']>;
};

export type ConfirmEmailInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type ForgotPasswordInput = {
  customerCode: Scalars['String'];
  email: Scalars['String'];
  isInitialLogin: Scalars['Boolean'];
};

export type IdentityProviderSettingsOutput = {
  __typename?: 'IdentityProviderSettingsOutput';
  enabled: Scalars['Boolean'];
  url: Scalars['String'];
};

export type InviteInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountSetup: AccountSetupOutput;
  confirmEmail: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  handleGoogleSignIn: SignInOutput;
  handleMicrosoftSignIn: SignInOutput;
  handleOktaSignIn: SignInOutput;
  isInitialLogin: Scalars['Boolean'];
  resetPassword: Scalars['String'];
  signIn: SignInOutput;
  signUp: SignInOutput;
  vintraceLogin: VintraceLoginOutput;
  vintraceRedirect: VintraceRedirectOutput;
};


export type MutationAccountSetupArgs = {
  data: AccountSetupInput;
};


export type MutationConfirmEmailArgs = {
  data: ConfirmEmailInput;
};


export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput;
};


export type MutationHandleGoogleSignInArgs = {
  code: Scalars['String'];
  state: Scalars['String'];
};


export type MutationHandleMicrosoftSignInArgs = {
  code: Scalars['String'];
  state: Scalars['String'];
};


export type MutationHandleOktaSignInArgs = {
  state: Scalars['String'];
  tokens: Scalars['String'];
};


export type MutationIsInitialLoginArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSignInArgs = {
  data: SignInInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationVintraceLoginArgs = {
  data: VintraceLoginInput;
};


export type MutationVintraceRedirectArgs = {
  customerCode: Scalars['String'];
};

export type OktaAuthOptionsOutput = {
  __typename?: 'OktaAuthOptionsOutput';
  clientId: Scalars['String'];
  issuer: Scalars['String'];
  redirectUri: Scalars['String'];
  state: Scalars['String'];
};

export type OktaAuthSettingsOutput = {
  __typename?: 'OktaAuthSettingsOutput';
  enabled: Scalars['Boolean'];
  options?: Maybe<OktaAuthOptionsOutput>;
};

export type Query = {
  __typename?: 'Query';
  getInvitation: UserInviteData;
  getSignInSettings: SignInSettingsOutput;
  getUserVintraceAccess: Array<UserVintraceAccessOutput>;
  isCarafed: Scalars['Boolean'];
  userInfo: UserProfile;
};


export type QueryGetInvitationArgs = {
  data: InviteInput;
};


export type QueryGetSignInSettingsArgs = {
  authStateInput: AuthStateInput;
};


export type QueryIsCarafedArgs = {
  customerCode: Scalars['String'];
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignInInput = {
  clientId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  redirectUri?: Maybe<Scalars['String']>;
  sandboxManagement?: Maybe<Scalars['Boolean']>;
};

export type SignInOutput = {
  __typename?: 'SignInOutput';
  apiUrl?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  invitationAccepted?: Maybe<Scalars['Boolean']>;
  redirectUri?: Maybe<Scalars['String']>;
  returnTo?: Maybe<Scalars['String']>;
  sandboxManagement?: Maybe<Scalars['Boolean']>;
  subsequentInvite?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['String']>;
};

export type SignInSettingsOutput = {
  __typename?: 'SignInSettingsOutput';
  apple: IdentityProviderSettingsOutput;
  basic: IdentityProviderSettingsOutput;
  google: IdentityProviderSettingsOutput;
  microsoft: IdentityProviderSettingsOutput;
  okta: OktaAuthSettingsOutput;
};

export type SignUpInput = {
  /** invitation code */
  code?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UserInviteData = {
  __typename?: 'UserInviteData';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shouldSignIn: Scalars['Boolean'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatar: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserVintraceAccessOutput = {
  __typename?: 'UserVintraceAccessOutput';
  allWineryAccess: Scalars['Boolean'];
  customerCode: Scalars['String'];
  customerName: Scalars['String'];
  isLocalAdmin: Scalars['Boolean'];
  isOwnerLogin: Scalars['Boolean'];
};

export type VintraceLoginInput = {
  customerCode: Scalars['String'];
  endOtherSessions?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type VintraceLoginOutput = {
  __typename?: 'VintraceLoginOutput';
  apiUrl: Scalars['String'];
  customerCode: Scalars['String'];
  token: Scalars['String'];
};

export type VintraceRedirectOutput = {
  __typename?: 'VintraceRedirectOutput';
  customerCode: Scalars['String'];
  tokenId: Scalars['String'];
};

export type SignInDataFragment = (
  { __typename?: 'SignInOutput' }
  & Pick<SignInOutput, 'apiUrl' | 'customerCode' | 'invitationAccepted' | 'redirectUri' | 'returnTo' | 'subsequentInvite' | 'token' | 'tokenId' | 'sandboxManagement'>
);

export type AccountSetupMutationVariables = Exact<{
  data: AccountSetupInput;
}>;


export type AccountSetupMutation = (
  { __typename?: 'Mutation' }
  & { accountSetup: (
    { __typename?: 'AccountSetupOutput' }
    & Pick<AccountSetupOutput, 'customerCode' | 'tokenId'>
  ) }
);

export type ConfirmEmailMutationVariables = Exact<{
  data: ConfirmEmailInput;
}>;


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmEmail'>
);

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type HandleGoogleSignInMutationVariables = Exact<{
  code: Scalars['String'];
  state: Scalars['String'];
}>;


export type HandleGoogleSignInMutation = (
  { __typename?: 'Mutation' }
  & { handleGoogleSignIn: (
    { __typename?: 'SignInOutput' }
    & SignInDataFragment
  ) }
);

export type IsInitialLoginMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsInitialLoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'isInitialLogin'>
);

export type MicrosoftSignInMutationVariables = Exact<{
  code: Scalars['String'];
  state: Scalars['String'];
}>;


export type MicrosoftSignInMutation = (
  { __typename?: 'Mutation' }
  & { handleMicrosoftSignIn: (
    { __typename?: 'SignInOutput' }
    & SignInDataFragment
  ) }
);

export type OktaSignInMutationVariables = Exact<{
  tokens: Scalars['String'];
  state: Scalars['String'];
}>;


export type OktaSignInMutation = (
  { __typename?: 'Mutation' }
  & { handleOktaSignIn: (
    { __typename?: 'SignInOutput' }
    & SignInDataFragment
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type SignInMutationVariables = Exact<{
  data: SignInInput;
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn: (
    { __typename?: 'SignInOutput' }
    & SignInDataFragment
  ) }
);

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp: (
    { __typename?: 'SignInOutput' }
    & SignInDataFragment
  ) }
);

export type VintraceLoginMutationVariables = Exact<{
  data: VintraceLoginInput;
}>;


export type VintraceLoginMutation = (
  { __typename?: 'Mutation' }
  & { vintraceLogin: (
    { __typename?: 'VintraceLoginOutput' }
    & Pick<VintraceLoginOutput, 'apiUrl' | 'customerCode' | 'token'>
  ) }
);

export type VintraceRedirectMutationVariables = Exact<{
  customerCode: Scalars['String'];
}>;


export type VintraceRedirectMutation = (
  { __typename?: 'Mutation' }
  & { vintraceRedirect: (
    { __typename?: 'VintraceRedirectOutput' }
    & Pick<VintraceRedirectOutput, 'tokenId' | 'customerCode'>
  ) }
);

export type GetInvitationQueryVariables = Exact<{
  data: InviteInput;
}>;


export type GetInvitationQuery = (
  { __typename?: 'Query' }
  & { getInvitation: (
    { __typename?: 'UserInviteData' }
    & Pick<UserInviteData, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'shouldSignIn' | 'isExpired'>
  ) }
);

export type GetSignInSettingsQueryVariables = Exact<{
  authState: AuthStateInput;
}>;


export type GetSignInSettingsQuery = (
  { __typename?: 'Query' }
  & { getSignInSettings: (
    { __typename?: 'SignInSettingsOutput' }
    & { apple: (
      { __typename?: 'IdentityProviderSettingsOutput' }
      & Pick<IdentityProviderSettingsOutput, 'enabled' | 'url'>
    ), google: (
      { __typename?: 'IdentityProviderSettingsOutput' }
      & Pick<IdentityProviderSettingsOutput, 'enabled' | 'url'>
    ), microsoft: (
      { __typename?: 'IdentityProviderSettingsOutput' }
      & Pick<IdentityProviderSettingsOutput, 'enabled' | 'url'>
    ), basic: (
      { __typename?: 'IdentityProviderSettingsOutput' }
      & Pick<IdentityProviderSettingsOutput, 'enabled' | 'url'>
    ), okta: (
      { __typename?: 'OktaAuthSettingsOutput' }
      & Pick<OktaAuthSettingsOutput, 'enabled'>
      & { options?: Maybe<(
        { __typename?: 'OktaAuthOptionsOutput' }
        & Pick<OktaAuthOptionsOutput, 'issuer' | 'clientId' | 'state' | 'redirectUri'>
      )> }
    ) }
  ) }
);

export type GetVintraceAccessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVintraceAccessQuery = (
  { __typename?: 'Query' }
  & { getUserVintraceAccess: Array<(
    { __typename?: 'UserVintraceAccessOutput' }
    & Pick<UserVintraceAccessOutput, 'customerName' | 'customerCode' | 'isLocalAdmin' | 'allWineryAccess' | 'isOwnerLogin'>
  )> }
);

export type IsCarafedQueryVariables = Exact<{
  customerCode: Scalars['String'];
}>;


export type IsCarafedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isCarafed'>
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountSetupInput: AccountSetupInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  AccountSetupOutput: ResolverTypeWrapper<AccountSetupOutput>;
  AuthStateInput: AuthStateInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ConfirmEmailInput: ConfirmEmailInput;
  ForgotPasswordInput: ForgotPasswordInput;
  IdentityProviderSettingsOutput: ResolverTypeWrapper<IdentityProviderSettingsOutput>;
  InviteInput: InviteInput;
  Mutation: ResolverTypeWrapper<{}>;
  OktaAuthOptionsOutput: ResolverTypeWrapper<OktaAuthOptionsOutput>;
  OktaAuthSettingsOutput: ResolverTypeWrapper<OktaAuthSettingsOutput>;
  Query: ResolverTypeWrapper<{}>;
  ResetPasswordInput: ResetPasswordInput;
  SignInInput: SignInInput;
  SignInOutput: ResolverTypeWrapper<SignInOutput>;
  SignInSettingsOutput: ResolverTypeWrapper<SignInSettingsOutput>;
  SignUpInput: SignUpInput;
  UserInviteData: ResolverTypeWrapper<UserInviteData>;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  UserVintraceAccessOutput: ResolverTypeWrapper<UserVintraceAccessOutput>;
  VintraceLoginInput: VintraceLoginInput;
  VintraceLoginOutput: ResolverTypeWrapper<VintraceLoginOutput>;
  VintraceRedirectOutput: ResolverTypeWrapper<VintraceRedirectOutput>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountSetupInput: AccountSetupInput;
  String: Scalars['String'];
  AccountSetupOutput: AccountSetupOutput;
  AuthStateInput: AuthStateInput;
  Boolean: Scalars['Boolean'];
  ConfirmEmailInput: ConfirmEmailInput;
  ForgotPasswordInput: ForgotPasswordInput;
  IdentityProviderSettingsOutput: IdentityProviderSettingsOutput;
  InviteInput: InviteInput;
  Mutation: {};
  OktaAuthOptionsOutput: OktaAuthOptionsOutput;
  OktaAuthSettingsOutput: OktaAuthSettingsOutput;
  Query: {};
  ResetPasswordInput: ResetPasswordInput;
  SignInInput: SignInInput;
  SignInOutput: SignInOutput;
  SignInSettingsOutput: SignInSettingsOutput;
  SignUpInput: SignUpInput;
  UserInviteData: UserInviteData;
  UserProfile: UserProfile;
  UserVintraceAccessOutput: UserVintraceAccessOutput;
  VintraceLoginInput: VintraceLoginInput;
  VintraceLoginOutput: VintraceLoginOutput;
  VintraceRedirectOutput: VintraceRedirectOutput;
};

export type AccountSetupOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountSetupOutput'] = ResolversParentTypes['AccountSetupOutput']> = {
  customerCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderSettingsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityProviderSettingsOutput'] = ResolversParentTypes['IdentityProviderSettingsOutput']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  accountSetup?: Resolver<ResolversTypes['AccountSetupOutput'], ParentType, ContextType, RequireFields<MutationAccountSetupArgs, 'data'>>;
  confirmEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationConfirmEmailArgs, 'data'>>;
  forgotPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'data'>>;
  handleGoogleSignIn?: Resolver<ResolversTypes['SignInOutput'], ParentType, ContextType, RequireFields<MutationHandleGoogleSignInArgs, 'code' | 'state'>>;
  handleMicrosoftSignIn?: Resolver<ResolversTypes['SignInOutput'], ParentType, ContextType, RequireFields<MutationHandleMicrosoftSignInArgs, 'code' | 'state'>>;
  handleOktaSignIn?: Resolver<ResolversTypes['SignInOutput'], ParentType, ContextType, RequireFields<MutationHandleOktaSignInArgs, 'state' | 'tokens'>>;
  isInitialLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationIsInitialLoginArgs, 'email'>>;
  resetPassword?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'data'>>;
  signIn?: Resolver<ResolversTypes['SignInOutput'], ParentType, ContextType, RequireFields<MutationSignInArgs, 'data'>>;
  signUp?: Resolver<ResolversTypes['SignInOutput'], ParentType, ContextType, RequireFields<MutationSignUpArgs, 'input'>>;
  vintraceLogin?: Resolver<ResolversTypes['VintraceLoginOutput'], ParentType, ContextType, RequireFields<MutationVintraceLoginArgs, 'data'>>;
  vintraceRedirect?: Resolver<ResolversTypes['VintraceRedirectOutput'], ParentType, ContextType, RequireFields<MutationVintraceRedirectArgs, 'customerCode'>>;
};

export type OktaAuthOptionsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['OktaAuthOptionsOutput'] = ResolversParentTypes['OktaAuthOptionsOutput']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  issuer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OktaAuthSettingsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['OktaAuthSettingsOutput'] = ResolversParentTypes['OktaAuthSettingsOutput']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['OktaAuthOptionsOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getInvitation?: Resolver<ResolversTypes['UserInviteData'], ParentType, ContextType, RequireFields<QueryGetInvitationArgs, 'data'>>;
  getSignInSettings?: Resolver<ResolversTypes['SignInSettingsOutput'], ParentType, ContextType, RequireFields<QueryGetSignInSettingsArgs, 'authStateInput'>>;
  getUserVintraceAccess?: Resolver<Array<ResolversTypes['UserVintraceAccessOutput']>, ParentType, ContextType>;
  isCarafed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsCarafedArgs, 'customerCode'>>;
  userInfo?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
};

export type SignInOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignInOutput'] = ResolversParentTypes['SignInOutput']> = {
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invitationAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  redirectUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sandboxManagement?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  subsequentInvite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignInSettingsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignInSettingsOutput'] = ResolversParentTypes['SignInSettingsOutput']> = {
  apple?: Resolver<ResolversTypes['IdentityProviderSettingsOutput'], ParentType, ContextType>;
  basic?: Resolver<ResolversTypes['IdentityProviderSettingsOutput'], ParentType, ContextType>;
  google?: Resolver<ResolversTypes['IdentityProviderSettingsOutput'], ParentType, ContextType>;
  microsoft?: Resolver<ResolversTypes['IdentityProviderSettingsOutput'], ParentType, ContextType>;
  okta?: Resolver<ResolversTypes['OktaAuthSettingsOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInviteDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInviteData'] = ResolversParentTypes['UserInviteData']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shouldSignIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserVintraceAccessOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVintraceAccessOutput'] = ResolversParentTypes['UserVintraceAccessOutput']> = {
  allWineryAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  customerCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isLocalAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOwnerLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VintraceLoginOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VintraceLoginOutput'] = ResolversParentTypes['VintraceLoginOutput']> = {
  apiUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VintraceRedirectOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VintraceRedirectOutput'] = ResolversParentTypes['VintraceRedirectOutput']> = {
  customerCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccountSetupOutput?: AccountSetupOutputResolvers<ContextType>;
  IdentityProviderSettingsOutput?: IdentityProviderSettingsOutputResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OktaAuthOptionsOutput?: OktaAuthOptionsOutputResolvers<ContextType>;
  OktaAuthSettingsOutput?: OktaAuthSettingsOutputResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  SignInOutput?: SignInOutputResolvers<ContextType>;
  SignInSettingsOutput?: SignInSettingsOutputResolvers<ContextType>;
  UserInviteData?: UserInviteDataResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  UserVintraceAccessOutput?: UserVintraceAccessOutputResolvers<ContextType>;
  VintraceLoginOutput?: VintraceLoginOutputResolvers<ContextType>;
  VintraceRedirectOutput?: VintraceRedirectOutputResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export const SignInDataFragmentDoc = gql`
    fragment SignInData on SignInOutput {
  apiUrl
  customerCode
  invitationAccepted
  redirectUri
  returnTo
  subsequentInvite
  token
  tokenId
  sandboxManagement
}
    `;
export const AccountSetupDocument = gql`
    mutation AccountSetup($data: AccountSetupInput!) {
  accountSetup(data: $data) {
    customerCode
    tokenId
  }
}
    `;

export function useAccountSetupMutation() {
  return Urql.useMutation<AccountSetupMutation, AccountSetupMutationVariables>(AccountSetupDocument);
};
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($data: ConfirmEmailInput!) {
  confirmEmail(data: $data)
}
    `;

export function useConfirmEmailMutation() {
  return Urql.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument);
};
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($data: ForgotPasswordInput!) {
  forgotPassword(data: $data)
}
    `;

export function useForgotPasswordMutation() {
  return Urql.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument);
};
export const HandleGoogleSignInDocument = gql`
    mutation HandleGoogleSignIn($code: String!, $state: String!) {
  handleGoogleSignIn(code: $code, state: $state) {
    ...SignInData
  }
}
    ${SignInDataFragmentDoc}`;

export function useHandleGoogleSignInMutation() {
  return Urql.useMutation<HandleGoogleSignInMutation, HandleGoogleSignInMutationVariables>(HandleGoogleSignInDocument);
};
export const IsInitialLoginDocument = gql`
    mutation IsInitialLogin($email: String!) {
  isInitialLogin(email: $email)
}
    `;

export function useIsInitialLoginMutation() {
  return Urql.useMutation<IsInitialLoginMutation, IsInitialLoginMutationVariables>(IsInitialLoginDocument);
};
export const MicrosoftSignInDocument = gql`
    mutation MicrosoftSignIn($code: String!, $state: String!) {
  handleMicrosoftSignIn(code: $code, state: $state) {
    ...SignInData
  }
}
    ${SignInDataFragmentDoc}`;

export function useMicrosoftSignInMutation() {
  return Urql.useMutation<MicrosoftSignInMutation, MicrosoftSignInMutationVariables>(MicrosoftSignInDocument);
};
export const OktaSignInDocument = gql`
    mutation OktaSignIn($tokens: String!, $state: String!) {
  handleOktaSignIn(tokens: $tokens, state: $state) {
    ...SignInData
  }
}
    ${SignInDataFragmentDoc}`;

export function useOktaSignInMutation() {
  return Urql.useMutation<OktaSignInMutation, OktaSignInMutationVariables>(OktaSignInDocument);
};
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data)
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const SignInDocument = gql`
    mutation SignIn($data: SignInInput!) {
  signIn(data: $data) {
    ...SignInData
  }
}
    ${SignInDataFragmentDoc}`;

export function useSignInMutation() {
  return Urql.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument);
};
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    ...SignInData
  }
}
    ${SignInDataFragmentDoc}`;

export function useSignUpMutation() {
  return Urql.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument);
};
export const VintraceLoginDocument = gql`
    mutation VintraceLogin($data: VintraceLoginInput!) {
  vintraceLogin(data: $data) {
    apiUrl
    customerCode
    token
  }
}
    `;

export function useVintraceLoginMutation() {
  return Urql.useMutation<VintraceLoginMutation, VintraceLoginMutationVariables>(VintraceLoginDocument);
};
export const VintraceRedirectDocument = gql`
    mutation VintraceRedirect($customerCode: String!) {
  vintraceRedirect(customerCode: $customerCode) {
    tokenId
    customerCode
  }
}
    `;

export function useVintraceRedirectMutation() {
  return Urql.useMutation<VintraceRedirectMutation, VintraceRedirectMutationVariables>(VintraceRedirectDocument);
};
export const GetInvitationDocument = gql`
    query GetInvitation($data: InviteInput!) {
  getInvitation(data: $data) {
    firstName
    lastName
    email
    phoneNumber
    shouldSignIn
    isExpired
  }
}
    `;

export function useGetInvitationQuery(options: Omit<Urql.UseQueryArgs<GetInvitationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetInvitationQuery>({ query: GetInvitationDocument, ...options });
};
export const GetSignInSettingsDocument = gql`
    query GetSignInSettings($authState: AuthStateInput!) {
  getSignInSettings(authStateInput: $authState) {
    apple {
      enabled
      url
    }
    google {
      enabled
      url
    }
    microsoft {
      enabled
      url
    }
    basic {
      enabled
      url
    }
    okta {
      enabled
      options {
        issuer
        clientId
        state
        redirectUri
      }
    }
  }
}
    `;

export function useGetSignInSettingsQuery(options: Omit<Urql.UseQueryArgs<GetSignInSettingsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetSignInSettingsQuery>({ query: GetSignInSettingsDocument, ...options });
};
export const GetVintraceAccessDocument = gql`
    query GetVintraceAccess {
  getUserVintraceAccess {
    customerName
    customerCode
    isLocalAdmin
    allWineryAccess
    isOwnerLogin
  }
}
    `;

export function useGetVintraceAccessQuery(options: Omit<Urql.UseQueryArgs<GetVintraceAccessQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetVintraceAccessQuery>({ query: GetVintraceAccessDocument, ...options });
};
export const IsCarafedDocument = gql`
    query IsCarafed($customerCode: String!) {
  isCarafed(customerCode: $customerCode)
}
    `;

export function useIsCarafedQuery(options: Omit<Urql.UseQueryArgs<IsCarafedQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<IsCarafedQuery>({ query: IsCarafedDocument, ...options });
};