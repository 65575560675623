import React, { useEffect } from 'react';
import {
  FormControl,
  FormErrorMessage,
  Button,
  Link,
  Flex,
  useDisclosure,
  Spinner,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import {
  PasswordInput,
  Label,
  EmailInput,
  NormalText,
  errorsContainsOneOf,
} from '@carafe/components';
import { useForm } from 'react-hook-form';
import { Routes } from '@routes';
import { Social } from './Social';
import { strings } from '@localisation';
import { SignInFormData, useAccount } from '@account';
import { errorCodeParser, messageForCodes } from '@errors';
import { emailErrorCodes, passwordErrorCodes } from '@carafe/errors';
import { useIsInitialLoginMutation } from '@generated/graphql';

import { useSocialLoginUrls } from '@hooks';
import { Continue } from '@components/account/Continue';

export const SignInForm = (): JSX.Element => {
  const { onSignIn, invite, isLoading, signInError, customerCode } =
    useAccount();
  const { register, handleSubmit, errors, clearErrors, setValue, getValues } =
    useForm<SignInFormData>({
      mode: 'onBlur',
      reValidateMode: 'onChange',
    });

  const { isFetching, links } = useSocialLoginUrls();

  useEffect(() => {
    if (invite) {
      setValue('email', invite.email);
    }
  }, [invite, setValue, links]);

  const _errors = errorCodeParser(signInError?.graphQLErrors);

  // TODO: Delete when customer onboarding is done

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ data: il }, executeIsInitialLoginMutation] =
    useIsInitialLoginMutation();

  const history = useHistory();
  useEffect(() => {
    if (il?.isInitialLogin) {
      onOpen();
    }
  }, [il?.isInitialLogin, onOpen]);

  const onResetNavigation = () => {
    const { email } = getValues();
    history.push(Routes.ForgotPassword, {
      isInitialLogin: true,
      customerCode,
      email,
    });
  };

  const onForgotPassword = () => {
    const { email } = getValues();
    history.push(Routes.ForgotPassword, {
      customerCode,
      email,
    });
  };

  const hasSocial =
    links?.apple.enabled ||
    links?.google.enabled ||
    links?.microsoft.enabled ||
    links?.okta.enabled;

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="500" fontSize="1rem">
            {strings.initialLoginModal.title}
          </ModalHeader>
          <ModalBody fontSize="0.875rem">
            {strings.initialLoginModal.description}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              _hover={{ backgroundColor: 'teal.500' }}
              colorScheme="teal"
              backgroundColor="teal.600"
              onClick={onResetNavigation}
            >
              {strings.initialLoginModal.continue}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isFetching && (
        <Center height="auto">
          <Spinner
            size="lg"
            speed="0.6s"
            emptyColor="gray.300"
            color="teal.600"
          />
        </Center>
      )}

      {links && <Social links={links} />}

      {hasSocial && <Continue>{strings.social.continue}</Continue>}

      <Flex
        as={'form'}
        flexDir={'column'}
        height={'100%'}
        onSubmit={handleSubmit((onValid) => {
          clearErrors();
          onSignIn(onValid);
        })}
      >
        <FormControl
          mb=".75rem"
          isInvalid={
            !!errors.email || errorsContainsOneOf(_errors, emailErrorCodes)
          }
        >
          <Label fontSize="0.75rem" htmlFor="email">
            {strings.signIn.usernameLabel}
          </Label>
          <EmailInput
            onBlur={(e) =>
              executeIsInitialLoginMutation({ email: e.target.value })
            }
            id="email"
            name="email"
            placeholder={strings.form.emailPlaceholder}
            backgroundColor="gray.25"
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: strings.form.emailValidation,
              },
            })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText
              text={
                messageForCodes(_errors, emailErrorCodes) ||
                strings.form.emailValidation
              }
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          marginBottom="auto"
          isInvalid={
            !!errors.password ||
            errorsContainsOneOf(_errors, passwordErrorCodes)
          }
        >
          <Label fontSize="0.75rem" htmlFor="password">
            {strings.signIn.passwordLabel}
          </Label>
          <PasswordInput
            id="password"
            name="password"
            placeholder={strings.signIn.passwordPlaceholder}
            isInvalid={!!errors.password}
            backgroundColor="gray.25"
            autoComplete="current-password"
            ref={register({
              required: strings.form.passwordRequired,
            })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText
              text={
                messageForCodes(_errors, passwordErrorCodes) ||
                strings.form.passwordValidation
              }
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={'1rem'}>
          <Button
            width="100%"
            type="submit"
            variant="solid"
            bgColor="teal.600"
            color="white"
            isLoading={isLoading}
          >
            {strings.signIn.button}
          </Button>
        </FormControl>
        <FormControl display="flex" justifyContent="center" mt="1rem">
          <Link onClick={onForgotPassword} color="teal.600">
            <NormalText
              text={strings.signIn.forgotPassword}
              fontSize="0.875rem"
            />
          </Link>
        </FormControl>
      </Flex>
    </>
  );
};
