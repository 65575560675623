import { Label, NormalText, TextInput } from '@carafe/components';
import { strings } from '@localisation';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { Ref } from 'react';
import { RegisterOptions } from 'react-hook-form';

interface FormCustomerCodeProps extends FormControlProps {
  register: (opts: RegisterOptions) => Ref<HTMLInputElement>;
  error: string;
}

export const FormCustomerCode = ({
  error,
  register,
}: FormCustomerCodeProps): JSX.Element => (
  <FormControl mb="1rem" isInvalid={!!error}>
    <Label htmlFor="customerCode">{strings.form.customerCodeLabel}</Label>
    <TextInput
      id="customerCode"
      name="customerCode"
      autoCapitalize="off"
      ref={register({ required: true })}
      placeholder={strings.form.customerCodeLabel}
    />
    <FormErrorMessage fontSize="0.75rem">
      <NormalText text={error} />
    </FormErrorMessage>
  </FormControl>
);
