import { createClient, dedupExchange, fetchExchange, Client } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { UpdatesConfig, KeyingConfig } from '@urql/exchange-graphcache';
// import {
//   GetSubscriptionDocument,
//   GetSubscriptionQuery,
//   UpdateAddonMutation,
// } from '@generated/graphql';

/**
 * Adds authorization header when available.
 * @param token JWT access token
 */
const requestOptions = (token?: string) => {
  if (!token) return {};
  const headers = { Authorization: `Bearer ${token}` };
  return { headers };
};

/**
 * After running a mutation, we need to specify which parts of the cache are
 * updated explicitly.
 */
const updates: Partial<UpdatesConfig> = {
  Mutation: {
    // updateAddon: (result, _, cache) => {
    //   cache.updateQuery<GetSubscriptionQuery>(
    //     { query: GetSubscriptionDocument },
    //     (data) => {
    //       if (!data) {
    //         return null;
    //       }
    //       const castresult = result as UpdateAddonMutation;
    //       const idx = data.getSubscription.addons.findIndex(
    //         (addon) => addon.itemCode === castresult.updateAddon.itemCode,
    //       );
    //       if (idx !== -1) {
    //         data.getSubscription.addons.splice(idx, 1, castresult.updateAddon);
    //       }
    //       return data;
    //     },
    //   );
    // },
  },
};

/**
 * The cache expects items to have either `id` or `_id`. If we have any data
 * that doesn't fit this, or need other behaviour, we can specify which property
 * to use as the unique id.
 */
const keys: KeyingConfig = {};

/**
 * Things that still need to be figured out
 * - should we use `requestPolicy: 'cache-and-network'` in the client config?
 * - how to create an introspection query that'll work with this.
 *
 * Don't use the regular `cacheExchange` from urql as it is not normalised and
 * harder to work with.
 *
 * Exchanges are basically just middleware.
 */
export const generateClient = (token?: string): Client => {
  const local = `${window.location.hostname}:4000`;
  const isLocal = window.location.host.includes(':3000');
  const url = isLocal ? local : window.location.hostname;
  const apiUrl = `https://${url}/api/graphql`;
  return createClient({
    url: apiUrl,
    fetchOptions: () => requestOptions(token),
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange({ keys, updates }),
      fetchExchange,
    ],
  });
};
