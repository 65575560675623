export enum VintracePermissionCode {
  ACCOUNT_HOLDER = 'ACCOUNT_HOLDER',
  CAN_ADD_PRODUCT_NOTES = 'CAN_ADD_PRODUCT_NOTES',
  CAN_ADD_EDIT_TRIAL_BLENDS = 'CAN_ADD_EDIT_TRIAL_BLENDS',
  CAN_ADD_EDIT_GROWERS_VINEYARDS_AND_BLOCKS = 'CAN_ADD_EDIT_GROWERS_VINEYARDS_AND_BLOCKS',
  CAN_ADJUST_COSTS = 'CAN_ADJUST_COSTS',
  CAN_ADJUST_LIVE_BONDS = 'CAN_ADJUST_LIVE_BONDS',
  CAN_ADJUST_WB_STOCK = 'CAN_ADJUST_WB_STOCK',
  CAN_ADJUST_TAX_STATE = 'CAN_ADJUST_TAX_STATE',
  CAN_BACK_DATE_ACCOUNTS_SYNC = 'CAN_BACK_DATE_ACCOUNTS_SYNC',
  CAN_PERFORM_BACKDATE_TTB702 = 'CAN_PERFORM_BACKDATE_TTB702',
  CAN_BYPASS_PACKAGING_RULES = 'CAN_BYPASS_PACKAGING_RULES',
  CAN_CHANGE_WINE_OWNERSHIP = 'CAN_CHANGE_WINE_OWNERSHIP',
  CAN_CHANGE_WINERY_DEFAULTS = 'CAN_CHANGE_WINERY_DEFAULTS',
  CAN_CREATE_DASHLETS = 'CAN_CREATE_DASHLETS',
  CAN_CREATE_PRODUCT_ALLOCATIONS = 'CAN_CREATE_PRODUCT_ALLOCATIONS',
  CAN_EDIT_EXISTING_PRODUCT_NOTES = 'CAN_EDIT_EXISTING_PRODUCT_NOTES',
  EDIT_TAX_VOLUME_EVENTS = 'EDIT_TAX_VOLUME_EVENTS',
  CAN_FORCE_ENTITY_UNLOCK = 'CAN_FORCE_ENTITY_UNLOCK',
  CAN_MANAGE_CLIENT_BILLING = 'CAN_MANAGE_CLIENT_BILLING',
  CAN_MANAGE_GROWER_CONTRACT = 'CAN_MANAGE_GROWER_CONTRACT',
  CAN_MANAGE_PRODUCT_ALLOCATIONS = 'CAN_MANAGE_PRODUCT_ALLOCATIONS',
  CAN_MANAGE_PURCHASE_ORDER = 'CAN_MANAGE_PURCHASE_ORDER',
  CAN_MANAGE_SALES_ORDER = 'CAN_MANAGE_SALES_ORDER',
  SUBSCRIPTION_ADMIN = 'SUBSCRIPTION_ADMIN',
  CAN_MANIPULATE_BOM = 'CAN_MANIPULATE_BOM',
  CAN_MOVE_WINE_BETWEEN_BONDS = 'CAN_MOVE_WINE_BETWEEN_BONDS',
  CAN_MOVE_WINE_BETWEEN_WINERIES = 'CAN_MOVE_WINE_BETWEEN_WINERIES',
  CAN_PERFORM_ADMIN_OPERATIONS = 'CAN_PERFORM_ADMIN_OPERATIONS',
  CAN_PERFORM_POWER_USER_OPERATIONS = 'CAN_PERFORM_POWER_USER_OPERATIONS',
  CAN_REASSIGN_APP_JOBS = 'CAN_REASSIGN_APP_JOBS',
  CAN_RECORD_LARGE_LOSS = 'CAN_RECORD_LARGE_LOSS',
  CAN_SUBMIT_MRP_ACTIONS = 'CAN_SUBMIT_MRP_ACTIONS',
  CAN_VIEW_COSTS = 'CAN_VIEW_COSTS',
  CAN_VIEW_PRODUCT_NOTES = 'CAN_VIEW_PRODUCT_NOTES',
  CAN_FUTURE_DATE_OPERATIONS = 'CAN_FUTURE_DATE_OPERATIONS',
  CAN_COMPLETE_TASKS_OUT_OF_SEQUENCE = 'CAN_COMPLETE_TASKS_OUT_OF_SEQUENCE',
  ENABLE_API_LOGIN = 'ENABLE_API_LOGIN',
  CAN_RESURRECT = 'CAN_RESURRECT',
  CAN_SCHEDULE_TASKS = 'CAN_SCHEDULE_TASKS',
  CAN_SUBMIT_OPERATIONS = 'CAN_SUBMIT_OPERATIONS',
  ADVANCED_DATA_ENTRY = 'ADVANCED_DATA_ENTRY',
  LAB_DATA_ADMIN = 'LAB_DATA_ADMIN',
  MODIFY_VESSEL_ALERT_STATE = 'MODIFY_VESSEL_ALERT_STATE',
  IMPORT_AND_EXPORT_SETUP_DATA = 'IMPORT_AND_EXPORT_SETUP_DATA',
  CAN_VIEW_PRODUCT_ALLOCATIONS = 'CAN_VIEW_PRODUCT_ALLOCATIONS',
  CAN_ADD_EDIT_VESSELS = 'CAN_ADD_EDIT_VESSELS',
  CAN_ADD_COSTS_ON_RECEIVAL = 'CAN_ADD_COSTS_ON_RECEIVAL',
  CAN_DISPATCH_NON_DECLARED_WINE = 'CAN_DISPATCH_NON_DECLARED_WINE',
  CAN_RUN_GROWER_CONTRACT_REPORT = 'CAN_RUN_GROWER_CONTRACT_REPORT',
  CAN_SET_BATCH_COSTS_TRACKED_PERC = 'CAN_SET_BATCH_COSTS_TRACKED_PERC',
}
