import React, { useEffect } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { MediumText, useQueryString } from '@carafe/components';
import { IoKeyOutline } from 'react-icons/io5';
import { strings } from '@localisation';
import { InvitationAcceptedSignIn } from '@components/invitations';

const go = `https://go.vintrace.net`;

export const AppleCallback = (): JSX.Element => {
  const [invitationAccepted, customerCode, tokenId] = useQueryString([
    'invitationAccepted',
    'customerCode',
    'tokenId',
  ]);

  useEffect(() => {
    async function handleRedirect(
      customerCode: string | null,
      invitationAccepted: string | null,
      tokenId: string | null,
    ) {
      if (!customerCode || !tokenId || !invitationAccepted) {
        // send to an error page.
        return;
      }
      await timer(2500);
      const goingTo = `${go}/${customerCode}/sso?code=${tokenId}`;
      window.location.href = goingTo;
    }
    handleRedirect(customerCode, invitationAccepted, tokenId);
  }, [customerCode, invitationAccepted, tokenId]);

  return (
    <Flex
      flexDir="column"
      minHeight="100vh"
      justify="center"
      align="center"
      width="100%"
      px={{ base: '1rem', md: 'unset' }}
      textAlign="center"
    >
      <IoKeyOutline size="1.5rem" />

      <MediumText fontSize="1.75rem" text={strings.access.title} my="0.75rem" />
      <Spinner
        size="xl"
        color="teal.500"
        thickness="2px"
        emptyColor="gray.200"
        speed="0.65s"
      />
      <MediumText
        my="0.75rem"
        color="gray.500"
        text={strings.access.description}
      />
      <InvitationAcceptedSignIn isOpen />
    </Flex>
  );
};

async function timer(msec: number) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
