export enum RoleType {
  Individual = 'Individual',
  Common = 'Common',
}

export enum VintraceRoleCode {
  CARRIER = 'CARRIER',
  COOPER = 'COOPER',
  CUSTOMER = 'CUSTOMER',
  GROWER = 'GROWER',
  LABORATORY = 'LABORATORY',
  OWNER = 'OWNER',
  VENDOR = 'VENDOR',
  DISTRIBUTOR = 'DISTRIBUTOR',
  HARVESTER = 'HARVESTER',
  LAB_TECHNICIAN = 'LAB_TECHNICIAN',
  OPERATOR = 'OPERATOR',
  SYSTEM_USER = 'SYSTEM_USER',
  WINE_MAKER = 'WINE_MAKER',
  OWNER_LOGIN = 'OWNER_LOGIN',
}
