import { Button } from '@chakra-ui/react';
import { IoArrowBackOutline, IoWarningOutline } from 'react-icons/io5';
import { Col, LightText, useQueryString } from '@carafe/components';
import { SsoErrorCode } from '@errors';
import { Centered } from '@layout';
import { strings } from '@localisation';
import { useHistory } from 'react-router-dom';
import { Routes } from '@routes';
import {
  CentralAuthErrorCode,
  SiwgErrorCode,
  SiwaErrorCode,
  SiwmErrorCode,
} from '@carafe/errors';
import { useMobileSsoErrorRedirect } from '@hooks';

const parseError = (errorId: string | null) => {
  if (errorId === null) {
    return SsoErrorCode.SSO_INTERNAL_ERROR;
  }
  if (errorId in SiwaErrorCode) {
    return errorId as SiwaErrorCode;
  }
  if (errorId in SiwgErrorCode) {
    return errorId as SiwgErrorCode;
  }
  if (errorId in SiwmErrorCode) {
    return errorId as SiwmErrorCode;
  }
  if (errorId in CentralAuthErrorCode) {
    return errorId as CentralAuthErrorCode;
  }
  return SsoErrorCode.SSO_INTERNAL_ERROR;
};

export const SignInWithError = (): JSX.Element => {
  const [errorId] = useQueryString(['errorId']);
  const error = parseError(errorId);
  const message = strings.error[error];
  const history = useHistory();

  const { clientId, redirectUri } = useMobileSsoErrorRedirect();

  const onNavigate = () => {
    if (clientId && redirectUri) {
      history.push(
        `${Routes.Home}?clientId=${clientId}&redirectUri=${redirectUri}`,
      );
    } else {
      history.push(Routes.Home);
    }
  };

  return (
    <Centered>
      <Col alignItems="center">
        <IoWarningOutline size="2rem" />
        <LightText
          py="1rem"
          textAlign="center"
          maxWidth={{ base: '95%', md: '30rem' }}
          text={message}
          fontSize="1.75rem"
        />
        <Button colorScheme="teal" onClick={onNavigate}>
          <IoArrowBackOutline size="1.25rem" />
          {strings.access.goBack}
        </Button>
      </Col>
    </Centered>
  );
};
