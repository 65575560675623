import React from 'react';
import { useForm } from 'react-hook-form';

import { Col } from '@carafe/components';
import {
  emailErrorCodes,
  passwordErrorCodes,
  customerCodeErrorCodes,
} from '@carafe/errors';

import { FormLogo } from '@components/forms';
import { strings } from '@localisation';
import { Centered } from '@layout';
import { useSignInMutation } from '@generated/graphql';
import { FormErrorMapper, getErrorCombiner } from '@errors/errorCombiner';
import { FormEmail } from '@components/forms/FormEmail';
import { FormCustomerCode } from '@components/forms/FormCustomerCode';
import { Form } from '@components/account';
import { FormPassword } from '@components/forms/FormPassword';
import { FormSubmitPrimary } from '@components/forms/FormSubmitPrimary';

const go = `https://go.vintrace.net`;

type FormData = {
  email: string;
  password: string;
  customerCode: string;
};

const adminSignInErrorMapper: FormErrorMapper = {
  email: emailErrorCodes,
  password: passwordErrorCodes,
  customerCode: customerCodeErrorCodes,
};

const adminSignInErrorCombiner = getErrorCombiner(adminSignInErrorMapper);

export const AdminSignIn = (): JSX.Element => {
  const [{ error: signInError, fetching: isLoading }, executeSignIn] =
    useSignInMutation();

  const {
    register,
    handleSubmit,
    errors: formErrors,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const errors = adminSignInErrorCombiner(
    formErrors,
    signInError?.graphQLErrors,
  );

  const onSubmit = handleSubmit(async (onValid) => {
    clearErrors();
    const { data: signInResult } = await executeSignIn({
      data: {
        ...onValid,
      },
    });
    if (signInResult?.signIn) {
      const goingTo = `${go}/${signInResult?.signIn.customerCode}/sso?code=${signInResult?.signIn.tokenId}`;
      window.location.href = goingTo;
    }
  });

  return (
    <>
      <Centered>
        <Col alignItems="center" maxWidth="21rem">
          <FormLogo />
          <Form p="1.5rem" onSubmit={onSubmit}>
            <FormCustomerCode register={register} error={errors.customerCode} />
            <FormEmail register={register} error={errors.email} />
            <FormPassword register={register} error={errors.password} />
            <FormSubmitPrimary
              isLoading={isLoading}
              text={strings.vintraceLogin.button}
            />
          </Form>
        </Col>
      </Centered>
    </>
  );
};
