export enum Routes {
  Home = '/',
  CustomerCode = '/customer-code',
  VintraceLogin = '/vintrace-login',
  AppLogin = '/app-login',
  InvitationExpired = '/invitation-expired',
  SignInSignUp = '/sign-in',
  AdminSignIn = '/admin-sign-in',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Dashboard = '/dashboard',
  ConfirmEmail = '/confirm',
  GoogleCallback = '/auth/google',
  MicrosoftCallback = '/auth/microsoft',
  AppleCallback = '/auth/apple',
  OktaCallback = '/auth/okta',
  SsoAuthError = '/auth/error',
  SignInWithError = '/auth/siwe',
  AccountSetup = '/account-setup',
  AppleSignIn = '/auth/apple/sign-in',
}
