import { WithChildren, useQueryString } from '@carafe/components';
import { useCallback, useEffect, useState } from 'react';
import {
  AccountContext,
  SignInData,
  SignInFormData,
  SignUpFormData,
} from './AccountContext';
import {
  useGetInvitationQuery,
  useSignInMutation,
  useSignUpMutation,
} from '@generated/graphql';
import { useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Routes } from '@routes';
import { useAuth } from '@auth';

const goUrlBase = process.env.GO_VINTRACE_URL;
const sandboxUrlBase = process.env.SANDBOX_VINTRACE_URL;

export const AccountProvider = ({ children }: WithChildren): JSX.Element => {
  const { setToken } = useAuth();

  const [
    email,
    code,
    returnTo,
    customerCode,
    state,
    clientId,
    redirectUri,
    sandboxManagement,
  ] = useQueryString([
    'email',
    'code',
    'returnTo',
    'customerCode',
    'state',
    'clientId',
    'redirectUri',
    'sandboxManagement',
  ]);

  const baseIndex = email && code ? 1 : 0;
  const [tabIndex, setTabIndex] = useState(baseIndex);
  const [willRedirect, setWillRedirect] = useState(false);

  const onTabChange = (index: number) => {
    setTabIndex(index);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onModalClose = () => {
    setTabIndex(0);
    onClose();
  };

  const history = useHistory();

  const [{ data: invite, error: getInviteError }] = useGetInvitationQuery({
    pause: code === null || email === null,
    variables: {
      data: { code: `${code}`, email: decodeURIComponent(`${email}`) },
    },
  });

  const [
    { data: signIn, error: signInError, fetching: signInFetching },
    executeSignIn,
  ] = useSignInMutation();

  const [
    { data: signUp, fetching: signUpFetching, error: signUpError },
    executeSignUp,
  ] = useSignUpMutation();

  const onSignIn = ({ email, password }: SignInFormData) => {
    executeSignIn({
      data: {
        email: email.trim(),
        password: password.trim(),
        code,
        customerCode,
        clientId,
        redirectUri,
        sandboxManagement: sandboxManagement?.toLowerCase() === 'true',
      },
    });
  };

  const onSignUp = ({ email, password, ...rest }: SignUpFormData) => {
    executeSignUp({
      input: { email: email.trim(), password: password.trim(), ...rest, code },
    });
  };
  // Handle redirecting user to customer code page where necessary
  useEffect(() => {
    // If have state, or customer code, do nothing
    if (customerCode || state) {
      return;
    }
    // In all other cases send the user to enter their customer code, and
    // maintain any current query params
    history.push(Routes.CustomerCode + window.location.search);
  }, [customerCode, history, state]);

  useEffect(() => {
    // If the invitation is expired redirect the user to the appropriate page
    if (invite?.getInvitation.isExpired || getInviteError) {
      history.push(Routes.InvitationExpired);
      return;
    }
    // if the invitation exists and indicates the user has an account then we
    // switch to the login tab.
    if (invite?.getInvitation.shouldSignIn) {
      setTabIndex(0);
    }
  }, [getInviteError, history, invite]);

  const handleRedirect = useCallback(
    (res: SignInData) => {
      const cc = res.customerCode || customerCode;
      if (clientId && redirectUri && res.token && res.apiUrl) {
        window.location.href = `${redirectUri}?token=${res.token}&apiUrl=${res.apiUrl}&customerCode=${cc}`;
        return;
      }

      // If we don't have a customerCode or tokenId, we ship the customer to the dashboard
      if (!cc || !res.tokenId) {
        setToken(res.token);
        history.push(Routes.Dashboard);
        return;
      }

      // If we have a customerCode and tokenId, we ship the customer off to either
      // sandbox management or the actual instance
      let goingTo: URL;
      if (sandboxManagement?.toLowerCase() === 'true') {
        goingTo = new URL(`${sandboxUrlBase}`);
        goingTo.pathname = `manage/${cc}/sso`;
      } else {
        goingTo = new URL(`${goUrlBase}`);
        goingTo.pathname = `${cc}/sso`;
      }

      goingTo.searchParams.append(`code`, res.tokenId);

      // Only adding a returnTo when available.
      if (returnTo) {
        goingTo.searchParams.append(`returnTo`, returnTo);
      }

      window.location.href = goingTo.toString();
    },
    [
      clientId,
      customerCode,
      history,
      redirectUri,
      returnTo,
      setToken,
      sandboxManagement,
    ],
  );

  // Display invitation accepted modal when user accepted invite and signed up
  // then redirect to vintrace
  useEffect(() => {
    async function handleInvitationAccepted(res: SignInData) {
      onOpen();
      await timer(2500);
      handleRedirect(res);
    }
    if (signUp?.signUp && signUp?.signUp.invitationAccepted) {
      handleInvitationAccepted(signUp.signUp);
    }
  }, [signUp?.signUp, onOpen, handleRedirect]);

  // Handle post-login redirection
  useEffect(() => {
    async function handleInvitationAccepted(res: SignInData) {
      onOpen();
      await timer(2500);
      handleRedirect(res);
    }

    if (signIn?.signIn) {
      setToken(signIn.signIn.token);
      if (signIn.signIn.customerCode) {
        setWillRedirect(true);
        const { invitationAccepted } = signIn.signIn;
        if (invitationAccepted) {
          handleInvitationAccepted(signIn.signIn);
        } else {
          handleRedirect(signIn.signIn);
        }
      } else {
        history.push(Routes.Dashboard);
      }
    }
  }, [
    clientId,
    customerCode,
    history,
    onOpen,
    redirectUri,
    returnTo,
    signIn?.signIn,
    setToken,
    handleRedirect,
  ]);

  return (
    <AccountContext.Provider
      value={{
        returnTo,
        customerCode,
        invite: invite?.getInvitation,
        tabIndex,
        isLoading: signUpFetching || signInFetching || willRedirect,
        isOpen: isOpen,
        onModalClose,
        onTabChange,
        onSignIn,
        onSignUp,
        signInError,
        signUpError,
        subsequentInvite: invite?.getInvitation.shouldSignIn ?? false,
        clientId,
        redirectUri,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

/**
 * Create an awaitable timer to delay execution where required.
 * @param msec time to wait in milliseconds
 */
async function timer(msec: number) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
