import {
  EmailInput,
  Label,
  Col,
  useQueryString,
  errorsContainsOneOf,
  PasswordInput,
  NormalText,
} from '@carafe/components';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Form } from '@components/account/Form';
import { Continue, Social} from '@components/account';
import { FormHeader, FormLogo } from '@components/forms';
import { Centered } from '@layout';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { strings } from '@localisation';
import { useHistory } from 'react-router';
import { Routes } from '@routes';
import { useAccountSetupMutation } from '@generated/graphql';
import { CentralAuthErrorCode } from '@carafe/errors';
import { errorCodeParser, messageForCodes } from '@errors';
import { useSocialLoginUrls } from '@hooks';

type FormState = {
  email: string;
  password: string;
};

const passwordErrorCodes = [
  CentralAuthErrorCode.PASSWORD_REQUIRED,
  CentralAuthErrorCode.PASSWORD_TOO_SHORT,
  CentralAuthErrorCode.NO_ACCESS_TO_APP,
  CentralAuthErrorCode.INVALID_CREDENTIALS,
];
export const AccountSetup = (): JSX.Element => {
  const { handleSubmit, register, setValue, errors } = useForm<FormState>();
  const [willRedirect, setWillRedirect] = useState(false);
  const [_email, customerCode] = useQueryString(['email', 'customerCode']);
  const [{ data, fetching, error }, accountSetup] = useAccountSetupMutation();
  const history = useHistory();

  const { isFetching, links } = useSocialLoginUrls();

  useEffect(() => {
    // Nobody should ever be on this page without customerCode and email params
    if (!customerCode || !_email) {
      history.push(Routes.Home);
      return;
    }
    // Set the correct value of the email into the form, required for submission
    setValue('email', _email);
  }, [_email, customerCode, history, setValue, links]);

  useEffect(() => {
    if (!data?.accountSetup) {
      return;
    }
    const { tokenId, customerCode } = data.accountSetup;
    window.location.href = `https://go.vintrace.net/${customerCode}/sso?code=${tokenId}`;
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors) {
      setWillRedirect(false);
    }
  }, [error?.graphQLErrors]);

  const onSubmit = ({ password }: FormState) => {
    if (!customerCode || !_email) {
      return;
    }
    setWillRedirect(true);
    accountSetup({ data: { email: _email, password, customerCode } });
  };

  const _errors = errorCodeParser(error?.graphQLErrors);

  const showOr =
    (links?.apple.enabled ||
      links?.google.enabled ||
      links?.microsoft.enabled ||
      links?.okta.enabled) &&
    links?.basic.enabled;

  return (
    <Centered>
      <Col alignItems="center" maxWidth="21rem">
        <FormLogo />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormHeader title={strings.accountSetup.title} />
          <Col px="1rem" width="100%">
            {isFetching && (
              <Center height="100%">
                <Spinner
                  size="lg"
                  speed="0.6s"
                  emptyColor="gray.300"
                  color="teal.600"
                />
              </Center>
            )}
            {links && <Social links={links} />}

            {showOr && <Continue>{strings.social.continue}</Continue>}

            {links?.basic.enabled && (
              <VStack alignItems="flex-start" spacing={4} minHeight="15rem">
                <FormControl>
                  <Label htmlFor="email">{strings.form.emailLabel}</Label>
                  <EmailInput
                    name="email"
                    id="email"
                    ref={register({ required: true })}
                    isDisabled
                  />
                </FormControl>
                <FormControl
                  isInvalid={
                    !!errors.password ||
                    errorsContainsOneOf(_errors, passwordErrorCodes)
                  }
                >
                  <Label htmlFor="password">{strings.form.passwordLabel}</Label>
                  <PasswordInput
                    name="password"
                    id="password"
                    ref={register({
                      required: {
                        message: strings.error.PASSWORD_REQUIRED,
                        value: true,
                      },
                      minLength: {
                        message: strings.error.PASSWORD_TOO_SHORT,
                        value: 10,
                      },
                      maxLength: {
                        message: strings.error.PASSWORD_TOO_LONG,
                        value: 64,
                      },
                    })}
                    placeholder={strings.form.passwordPlaceholder}
                  />
                  <FormErrorMessage fontSize="0.75rem">
                    <NormalText
                      text={
                        errors.password?.message ??
                        messageForCodes(_errors, passwordErrorCodes) ??
                        strings.error.PASSWORD_REQUIRED
                      }
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <Button
                    isFullWidth
                    type="submit"
                    colorScheme="teal"
                    bg="teal.600"
                    _hover={{ bg: 'teal.500' }}
                    isDisabled={fetching || willRedirect}
                  >
                    {strings.form.submit}
                  </Button>
                </FormControl>
              </VStack>
            )}
          </Col>
        </Form>
      </Col>
    </Centered>
  );
};
