export enum SsoErrorCode {
  SSO_INVALID_REQUEST = 'SSO_INVALID_REQUEST',
  SSO_INVALID_API_KEY = 'SSO_INVALID_API_KEY',
  SSO_INVALID_API_USERNAME = 'SSO_INVALID_API_USERNAME',
  SSO_INVALID_API_CREDENTIALS = 'SSO_INVALID_API_CREDENTIALS',
  SSO_INVALID_USER_PRIVILEGES = 'SSO_INVALID_USER_PRIVILEGES',
  SSO_INVALID_USER_ACCOUNT = 'SSO_INVALID_USER_ACCOUNT',
  SSO_INTERNAL_ERROR = 'SSO_INTERNAL_ERROR',
  SSO_INVALID_DATA = 'SSO_INVALID_DATA',
  SSO_TOO_MANY_USERS = 'SSO_TOO_MANY_USERS',
  SSO_TIMEOUT = 'SSO_TIMEOUT',
  SSO_INVALID_CLIENT_REQUEST = 'SSO_INVALID_CLIENT_REQUEST',
  SSO_FORBIDDEN = 'SSO_FORBIDDEN',
  SSO_LICENSE_RESTRICTION = 'SSO_LICENSE_RESTRICTION',
  FORCED_LOGOUT = 'FORCED_LOGOUT',
}
