import { createContext } from 'react';
import type { Nullable } from '@carafe/models';
import type { GetInvitationQuery, SignInMutation } from '@generated/graphql';
import type { CombinedError } from 'urql';

export interface AuthState {
  returnTo: string | null;
  email: string | null;
  code: string | null;
  customerCode: string | null;
  redirectUri: string | null;
  sandboxManagement: boolean | null;
}

export type SignInFormData = {
  email: string;
  password: string;
};

export type SignUpFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
};

export type SignInData = SignInMutation['signIn'];

export interface AccountContextInterface {
  returnTo: Nullable<string>;
  customerCode: Nullable<string>;
  invite?: GetInvitationQuery['getInvitation'];
  tabIndex: number;
  isLoading: boolean;
  isOpen: boolean;
  onModalClose: () => void;
  onTabChange: (index: number) => void;
  onSignIn: (data: SignInFormData) => void;
  onSignUp: (data: SignUpFormData) => void;
  signInError?: CombinedError;
  signUpError?: CombinedError;
  subsequentInvite: boolean;
  clientId: string | null;
  redirectUri: string | null;
}

/**
 * @ignore
 */
/* istanbul ignore next */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AccessProvider>.');
};

const initialContext: AccountContextInterface = {
  returnTo: null,
  customerCode: null,
  invite: undefined,
  tabIndex: 0,
  isLoading: false,
  isOpen: false,
  onModalClose: stub,
  onTabChange: stub,
  onSignIn: stub,
  onSignUp: stub,
  signInError: undefined,
  signUpError: undefined,
  subsequentInvite: false,
  clientId: null,
  redirectUri: null,
};

/**
 * The Auth Context
 */
export const AccountContext =
  createContext<AccountContextInterface>(initialContext);
