/** Item codes for subscriptions in Invoiced */
export enum ItemCode {
  /** Boutique: Additional User */
  VT_BOU_USER = 'VT_BOU_USER',
  /** Boutique: Additional User - Yearly */
  VT_BOU_USER_YEAR = 'VT_BOU_USER_YEAR',

  /** Small Estate: Additional User */
  VT_ESTS_USER = 'VT_ESTS_USER',
  /** Small Estate: Additional User - Yearly */
  VT_ESTS_USER_YEAR = 'VT_ESTS_USER_YEAR',
  /** Small Estate: Additional AP Bond */
  VT_ESTS_ALTP = 'VT_ESTS_ALTP',
  /** Small Estate: Additional AP Bond - Yearly */
  VT_ESTS_ALTP_YEAR = 'VT_ESTS_ALTP_YEAR',
  /** Small Estate: Extra Primary Bond */
  VT_ESTS_EPB = 'VT_ESTS_EPB',
  /** Small Estate: Extra Primary Bond - Yearly */
  VT_ESTS_EPB_YEAR = 'VT_ESTS_EPB_YEAR',
  /** Estate: Additional Winery */
  VT_ESTS_WNRY = 'VT_ESTS_WNRY',
  /** Estate: Additional Winery - Yearly */
  VT_ESTS_WNRY_YEAR = 'VT_ESTS_WNRY_YEAR',
  /** Small Estate: Offsite Storage Facility */
  VT_ESTS_OFF = 'VT_ESTS_OFF',
  /** Small Estate: Offsite Storage Facility - Yearly */
  VT_ESTS_OFF_YEAR = 'VT_ESTS_OFF_YEAR',
  /** Small Estate: Owner (Client) Login */
  VT_ESTS_OWNR = 'VT_ESTS_OWNR',
  /** Small Estate: Owner (Client) Login - Yearly */
  VT_ESTS_OWNR_YEAR = 'VT_ESTS_OWNR_YEAR',

  /** Estate: Additional AP Bond */
  VT_EST_ALTP = 'VT_EST_ALTP',
  /** Estate: Additional AP Bond - Yearly */
  VT_EST_ALTP_YEAR = 'VT_EST_ALTP_YEAR',
  /** Estate: Additional User - Yearly */
  VT_EST_USER = 'VT_EST_USER',
  /** Estate: Additional User */
  VT_EST_USER_YEAR = 'VT_EST_USER_YEAR',
  /** Estate: Extra Primary Bond */
  VT_EST_EPB = 'VT_EST_EPB',
  /** Estate: Extra Primary Bond - Yearly */
  VT_EST_EPB_YEAR = 'VT_EST_EPB_YEAR',
  /** Estate: Additional Winery */
  VT_EST_WNRY = 'VT_EST_WNRY',
  /** Estate: Additional Winery - Yearly */
  VT_EST_WNRY_YEAR = 'VT_EST_WNRY_YEAR',
  /** Estate: Offsite Storage Facility */
  VT_EST_OFF = 'VT_EST_OFF',
  /** Estate: Offsite Storage Facility - Yearly */
  VT_EST_OFF_YEAR = 'VT_EST_OFF_YEAR',
  /** Estate: Owner (Client) Login */
  VT_EST_OWNR = 'VT_EST_OWNR',
  /** Estate: Owner (Client) Login - Yearly */
  VT_EST_OWNR_YEAR = 'VT_EST_OWNR_YEAR',

  /** Custom Crush: Additional AP Bond */
  VT_CUS_ALTP = 'VT_CUS_ALTP',
  /** Custom Crush: Additional AP Bond - Yearly */
  VT_CUS_ALTP_YEAR = 'VT_CUS_ALTP_YEAR',
  /** Custom Crush: Additional Winery */
  VT_CUS_WNRY = 'VT_CUS_WNRY',
  /** Custom Crush: Additional Winery */
  VT_CUS_WNRY_YEAR = 'VT_CUS_WNRY_YEAR',
  /** Estate: Additional User */
  VT_CUS_USER = 'VT_CUS_USER',
  /** Estate: Additional User - Yearly*/
  VT_CUS_USER_YEAR = 'VT_CUS_USER_YEAR',
  /** Custom Crush: Extra Primary Bond */
  VT_CUS_EPB = 'VT_CUS_EPB',
  /** Custom Crush: Extra Primary Bond - Yearly */
  VT_CUS_EPB_YEAR = 'VT_CUS_EPB_YEAR',
  /** Custom Crush: Offsite Storage Facility */
  VT_CUS_OFF = 'VT_CUS_OFF',
  /** Custom Crush: Offsite Storage Facility - Yearly */
  VT_CUS_OFF_YEAR = 'VT_CUS_OFF_YEAR',

  /** Enterprise: Additional User */
  VT_ENT_USER = 'VT_ENT_USER',
  /** Enterprise: Additional User - Yearly */
  VT_ENT_USER_YEAR = 'VT_ENT_USER_YEAR',
  /** Enterprise: Offsite Storage Facility */
  VT_ENT_OFF = 'VT_ENT_OFF',
  /** Enterprise: Offsite Storage Facility - Yearly */
  VT_ENT_OFF_YEAR = 'VT_ENT_OFF_YEAR',
  /** Enterprise: Additional AP Bond */
  VT_ENT_ALTP = 'VT_ENT_ALTP',
  /** Enterprise: Additional AP Bond - Yearly */
  VT_ENT_ALTP_YEAR = 'VT_ENT_ALTP_YEAR',
  /** Enterprise: Additional Primary Bond */
  VT_ENT_EPB = 'VT_ENT_EPB',
  /** Enterprise: Additional Primary Bond - Yearly */
  VT_ENT_EPB_YEAR = 'VT_ENT_EPB_YEAR',
  /** Enterprise: Additional Winery */
  VT_ENT_WNRY = 'VT_ENT_WNRY',
  /** Enterprise: Additional Winery - Yearly */
  VT_ENT_WNRY_YEAR = 'VT_ENT_WNRY_YEAR',
  /** Vintrace Premium Harvest 24/7 Support */
  VT_ADD_HVST = 'VT_ADD_HVST',
  /** Vintrace Premium Harvest 24/7 Support - Yearly */
  VT_ADD_HVST_YEAR = 'VT_ADD_HVST_YEAR',
}
