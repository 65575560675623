export enum EmailType {
  Confirmation = 'Confirmation',
  Invitation = 'Invitation',
  PasswordReset = 'PasswordReset',
  UpgradeRequest = 'UpgradeRequest',
  EmailChanged = 'EmailChanged',
  PasswordChanged = 'PasswordChanged',
  AppleConsentRevoked = 'AppleConsentRevoked',
  AccessReenabled = 'AccessReenabled',
}
