import { Row, VintraceSingleMono } from '@carafe/components';
import { Box, Flex, Link, useColorModeValue } from '@chakra-ui/react';
import { strings } from '@localisation';
import { Routes } from '@routes';
import React from 'react';
import { Link as RRLink, useLocation } from 'react-router-dom';
import { ColorModeSwitcher } from './ColorModeSwitcher';

const navItems = [
  { route: Routes.SignInSignUp, text: strings.navigation.signIn },
  // { route: Routes.SignUp, text: strings.navigation.signUp },
];

export const Navigation = (): JSX.Element => {
  const { pathname } = useLocation();
  const bg = useColorModeValue('teal.500', 'teal.900');

  return (
    <Row bg={bg} as="nav" alignItems="center" px="1rem">
      <Box py="0.5rem" pr="0.75rem">
        <Box as={VintraceSingleMono} fill="white" />
      </Box>
      {navItems.map(({ route, text }) => {
        const active = pathname === route;
        const borderColor = active ? 'white' : 'transparent';
        const opacity = active ? 1 : 0.54;
        return (
          <Flex
            key={route}
            height="3rem"
            flexDir="column"
            borderBottom="2px solid"
            borderColor={borderColor}
            opacity={opacity}
            _hover={{ textDecor: 'none', borderColor: 'white', opacity: 1 }}
            alignItems="center"
            justifyContent="center"
          >
            <Link
              as={RRLink}
              to={route}
              color="white"
              height="auto"
              fontWeight="500"
              fontSize="0.875rem"
              px="0.75rem"
              _hover={{ textDecor: 'none' }}
            >
              {text}
            </Link>
          </Flex>
        );
      })}
      <ColorModeSwitcher />
    </Row>
  );
};
