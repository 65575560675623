import { LightText, NormalText } from '@carafe/components';
import { Flex } from '@chakra-ui/react';

interface Props {
  title: string;
  description?: string;
}

export const FormHeader = ({ title, description }: Props): JSX.Element => {
  return (
    <Flex width="100%" flexDir="column" alignItems="center" px="1rem" py="1rem">
      <LightText
        fontSize="1.5rem"
        pt="0.5rem"
        text={title}
        textAlign="center"
      />
      {description && (
        <NormalText
          text={description}
          textAlign="center"
          fontSize="0.875rem"
          color="gray.600"
        />
      )}
    </Flex>
  );
};
