import React, { Ref } from 'react';
import { EmailInput, Label, NormalText } from '@carafe/components';

import { strings } from '@localisation';

import {
  FormControl,
  FormErrorMessage,
  FormControlProps,
} from '@chakra-ui/react';
import { RegisterOptions } from 'react-hook-form';

interface FormEmailInputProps extends FormControlProps {
  register: (opts: RegisterOptions) => Ref<HTMLInputElement>;
  error: string;
}

export const FormEmail = ({
  register,
  error,
}: FormEmailInputProps): JSX.Element => (
  <FormControl mb="1rem" isInvalid={!!error}>
    <Label htmlFor="email">{strings.form.emailLabel}</Label>
    <EmailInput
      id="email"
      name="email"
      placeholder={strings.form.emailPlaceholder}
      backgroundColor="gray.25"
      ref={register({
        required: true,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: strings.form.emailValidation,
        },
      })}
    />
    <FormErrorMessage fontSize="0.75rem">
      <NormalText text={error} />
    </FormErrorMessage>
  </FormControl>
);
