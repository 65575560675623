import React, { useEffect } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { MediumText, useQueryString } from '@carafe/components';
import { IoKeyOutline } from 'react-icons/io5';
import { strings } from '@localisation';
import { useMicrosoftSignInMutation } from '@generated/graphql';
import { siwmErrorArray } from '@carafe/errors';
import {
  useSignInWithErrorRedirect,
  useSignInWithSuccessRedirect,
} from '@hooks';

import { InvitationAcceptedSignIn } from '@components/invitations';

export const MicrosoftCallback = (): JSX.Element => {
  const [code, state] = useQueryString(['code', 'state']);
  const [{ data, error }, exec] = useMicrosoftSignInMutation();

  useSignInWithErrorRedirect({
    codes: siwmErrorArray,
    gqlErrors: error?.graphQLErrors,
  });

  const { isOpen } = useSignInWithSuccessRedirect(data?.handleMicrosoftSignIn);

  useEffect(() => {
    if (code && state) {
      exec({ code, state });
    }
  }, [code, exec, state]);

  return (
    <Flex
      flexDir="column"
      minHeight="100vh"
      justify="center"
      align="center"
      width="100%"
      px={{ base: '1rem', md: 'unset' }}
      textAlign="center"
    >
      <IoKeyOutline size="1.5rem" />

      <MediumText fontSize="1.75rem" text={strings.access.title} my="0.75rem" />
      <Spinner
        size="xl"
        color="teal.500"
        thickness="2px"
        emptyColor="gray.200"
        speed="0.65s"
      />
      <MediumText
        my="0.75rem"
        color="gray.500"
        text={strings.access.description}
      />
      <InvitationAcceptedSignIn isOpen={isOpen} />
    </Flex>
  );
};
