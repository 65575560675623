import { useQueryString } from '@carafe/components';
import {
  SignInSettingsOutput,
  useGetSignInSettingsQuery,
} from '@generated/graphql';

interface UseSocialLoginUrls {
  isFetching: boolean;
  links?: SignInSettingsOutput;
}

export const useSocialLoginUrls = (): UseSocialLoginUrls => {
  const [email, code, returnTo, customerCode, redirectUri, sandboxManagement] =
    useQueryString([
      'email',
      'code',
      'returnTo',
      'customerCode',
      'redirectUri',
      'sandboxManagement',
    ]);

  const [{ fetching: isFetching, data: links }] = useGetSignInSettingsQuery({
    variables: {
      authState: {
        returnTo,
        email,
        code,
        customerCode: customerCode ?? '',
        redirectUri,
        origin: window.location.origin,
        sandboxManagement: sandboxManagement?.toLowerCase() === 'true',
      },
    },
  });

  return { isFetching, links: links?.getSignInSettings };
};
