import React, { Ref } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { Label, NormalText, PasswordInput } from '@carafe/components';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { strings } from '@localisation';

interface FormPasswordProps {
  register: (opts: RegisterOptions) => Ref<HTMLInputElement>;
  error: string;
}

export const FormPassword = ({
  register,
  error,
}: FormPasswordProps): JSX.Element => (
  <FormControl mb="1rem" isInvalid={!!error}>
    <Label htmlFor="password">{strings.vintraceLogin.password}</Label>
    <PasswordInput
      id="password"
      name="password"
      ref={register({ required: true })}
      placeholder={strings.vintraceLogin.passwordPlaceholder}
    />
    <FormErrorMessage fontSize="0.75rem">
      <NormalText text={error} />
    </FormErrorMessage>
  </FormControl>
);
