import { FormLogo } from '@components/forms';
import { Centered } from '@layout';
import { Row, Col, LightText, SkeletonCard } from '@carafe/components';
import { VintraceAccessCard } from '@components/user';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  useGetVintraceAccessQuery,
  GetVintraceAccessQuery,
  useVintraceRedirectMutation,
} from '@generated/graphql';
import { strings } from '@localisation';

type UserVintraceAccess = GetVintraceAccessQuery['getUserVintraceAccess'];
const go = `https://go.vintrace.net`;
const lccKey = 'lcc';
const useLastStoredCustomerCode = () => {
  const [lcc, setLcc] = useState('');
  const [vintraceAccess, setVintraceAccess] = useState<UserVintraceAccess>([]);
  const [{ data, fetching }] = useGetVintraceAccessQuery();
  const [{ data: vrd }, executeVintraceRedirect] =
    useVintraceRedirectMutation();

  const get = () => {
    try {
      const val = localStorage.getItem(lccKey);
      if (val) {
        setLcc(val);
      }
    } catch {}
  };

  const set = (customerCode: string) => {
    try {
      executeVintraceRedirect({ customerCode });
      localStorage.setItem(lccKey, customerCode);
    } catch {}
  };

  useEffect(() => {
    get();
  }, []);
  useEffect(() => {
    if (data?.getUserVintraceAccess) {
      setVintraceAccess(data.getUserVintraceAccess);
    }
  }, [data?.getUserVintraceAccess]);

  useEffect(() => {
    if (vrd) {
      const { tokenId, customerCode } = vrd.vintraceRedirect;
      const goingTo = `${go}/${customerCode}/sso?code=${tokenId}`;
      window.location.href = goingTo;
    }
  }, [vrd]);

  return { fetching, set, lcc, vintraceAccess };
};

/**
 * This page is for a user to choose a vintrace to be
 * redirected to. Click card for redirect
 */
export const Dashboard = (): JSX.Element => {
  const { fetching, set, lcc, vintraceAccess } = useLastStoredCustomerCode();
  return (
    <Centered>
      <Col width={{ base: '100%', md: '80%' }} px="0.5rem">
        <Row justifyContent="center">
          <FormLogo />
        </Row>
        <Flex flexDir="column" alignItems="center" minWidth="351px" pb="1rem">
          <Col width="100%">
            <LightText
              pt="0.5rem"
              pb="0.5rem"
              textAlign="center"
              fontSize="1.125rem"
              text={strings.dashboard.title}
            />
            <Col>
              <SimpleGrid minChildWidth="18.75rem" spacing="1rem">
                {fetching
                  ? new Array(5).fill('').map((_, idx) => {
                      return (
                        <SkeletonCard
                          key={idx}
                          rowCount={1}
                          border="0px"
                          width="100%"
                          spacing="1rem"
                          borderRadius="lg"
                          skeletonHeight="9rem"
                          p="0rem"
                        />
                      );
                    })
                  : vintraceAccess.map((va, idx) => {
                      const flags = {
                        isLocalAdmin: va.isLocalAdmin,
                        allWineryAccess: va.allWineryAccess,
                        isOwnerLogin: va.isOwnerLogin,
                        lcc: lcc === va.customerCode,
                      };
                      return (
                        <VintraceAccessCard
                          key={idx}
                          customerName={va.customerName}
                          flags={flags}
                          onClick={set}
                          customerCode={va.customerCode}
                        />
                      );
                    })}
              </SimpleGrid>
            </Col>
            <LightText
              color="gray.600"
              pt="1.5rem"
              textAlign="center"
              fontSize="0.75rem"
              text={strings.dashboard.helper}
            />
          </Col>
        </Flex>
      </Col>
    </Centered>
  );
};
