import { Flex, VStack } from '@chakra-ui/react';
import { GoogleSignInButton } from './GoogleSignInButton';
import { AppleSignInButton } from './AppleSignInButton';
import { MicrosoftSignInButton } from './MicrosoftSignInButton';
import { OktaSignInButton } from './OktaSignInButton';
import { SignInSettingsOutput } from '@generated/graphql';

interface SocialProps {
  links: SignInSettingsOutput;
}

export const Social = ({ links }: SocialProps): JSX.Element => {
  return (
    <Flex flexDir="column" width="100%">
      <VStack>
        {links.google.enabled && <GoogleSignInButton url={links.google.url} />}
        {links.apple.enabled && <AppleSignInButton url={links.apple.url} />}
        {links.microsoft.enabled && (
          <MicrosoftSignInButton url={links.microsoft.url} />
        )}
        {links.okta.enabled && links.okta.options && (
          <OktaSignInButton oktaAuthOptions={links.okta.options} />
        )}
      </VStack>
    </Flex>
  );
};
