import { useAccount } from '@account';
import { Celebration, Col, NormalText } from '@carafe/components';
import { Button, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { strings } from '@localisation';

const { invitation } = strings;

export const InvitationAccepted = (): JSX.Element => {
  const { isOpen, onModalClose, subsequentInvite } = useAccount();

  const description = subsequentInvite
    ? invitation.secondaryDescription
    : invitation.description;

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent py="1.5rem" maxWidth="23rem">
        <Col
          alignItems="center"
          justifyContent="center"
          maxWidth="20rem"
          mx="auto"
          textAlign="center"
        >
          <NormalText text={invitation.title} fontSize="1.75rem" />
          <NormalText
            text={description}
            fontSize="0.875rem"
            color="gray.600"
            mb="1rem"
          />
          <Celebration />
          {!subsequentInvite && (
            <Button colorScheme="teal" mt="2.75rem" onClick={onModalClose}>
              {invitation.cta}
            </Button>
          )}
        </Col>
      </ModalContent>
    </Modal>
  );
};
