import React, { useEffect } from 'react';
import {
  Label,
  PasswordInput,
  Col,
  useQueryString,
  EmailInput,
  errorsContainsOneOf,
  NormalText,
} from '@carafe/components';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { Form } from '@components/account/Form';
import { FormHeader, FormLogo } from '@components/forms';
import { strings } from '@localisation';
import { Centered } from '@layout';
import {
  useIsInitialLoginMutation,
  useSignInMutation,
} from '@generated/graphql';
import { Continue, Disclaimer } from '@components/account';
import { emailErrorCodes, passwordErrorCodes } from '@carafe/errors';
import { errorCodeParser, messageForCodes } from '@errors';
import { Social } from '@components/account/Social';
import { useSocialLoginUrls } from '@hooks';

type FormData = {
  email: string;
  password: string;
};

export const AppLogin = (): JSX.Element => {
  const [customerCode, redirectUri, clientId] = useQueryString([
    'customerCode',
    'redirectUri',
    'clientId',
  ]);
  // We need to store these in localStorage in the event that the users chooses
  // sign in with Apple or Google and that fails, so that on those error pages
  // we can use these values to redirect them through the process with the
  // correct query string parameters
  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem('redirectUri', redirectUri);
    }
    if (clientId) {
      localStorage.setItem('clientId', clientId);
    }
  }, [clientId, redirectUri]);

  const { handleSubmit, register, errors } = useForm<FormData>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [{ data }, checkInitialLogin] = useIsInitialLoginMutation();

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { data: signInRes, error: signInError, fetching: signInFetching },
    signIn,
  ] = useSignInMutation();
  const { isFetching, links } = useSocialLoginUrls();

  const onSubmit = ({ email, password }: FormData) => {
    signIn({
      data: {
        email: email.trim(),
        password: password.trim(),
        clientId,
        redirectUri,
        customerCode,
      },
    });
  };

  useEffect(() => {
    if (data?.isInitialLogin) {
      onOpen();
    }
  }, [data?.isInitialLogin, onOpen]);

  useEffect(() => {
    if (signInRes) {
      const { apiUrl, token, customerCode } = signInRes.signIn;
      window.location.href = `${redirectUri}?token=${token}&apiUrl=${apiUrl}&customerCode=${customerCode}`;
      return;
    }
  }, [redirectUri, signInRes]);

  const _errors = errorCodeParser(signInError?.graphQLErrors);

  const hasSocial =
    links?.apple.enabled ||
    links?.google.enabled ||
    links?.microsoft.enabled ||
    links?.okta.enabled;

  return (
    <>
      <Centered>
        <Col alignItems="center" maxWidth="21rem">
          <FormLogo />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDir="column" width="100%" px={6}>
              <FormHeader title={strings.vintraceLogin.title} />
              {isFetching && (
                <Center height="100%">
                  <Spinner
                    size="lg"
                    speed="0.6s"
                    emptyColor="gray.300"
                    color="teal.600"
                  />
                </Center>
              )}
              {links && <Social links={links} />}

              {hasSocial && <Continue>{strings.social.continue}</Continue>}

              <>
                <FormControl
                  mt="1rem"
                  isInvalid={
                    !!errors.email ||
                    errorsContainsOneOf(_errors, emailErrorCodes)
                  }
                >
                  <Label htmlFor="email">{strings.form.emailLabel}</Label>
                  <EmailInput
                    autoCorrect="off"
                    id="email"
                    name="email"
                    autoCapitalize="off"
                    ref={register({ required: true })}
                    placeholder={strings.form.emailPlaceholder}
                    onBlur={(e) => checkInitialLogin({ email: e.target.value })}
                  />
                  <FormErrorMessage fontSize="0.75rem">
                    <NormalText
                      text={
                        messageForCodes(_errors, emailErrorCodes) ||
                        strings.form.emailValidation
                      }
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  mt="2rem"
                  mb="3rem"
                  isInvalid={
                    !!errors.password ||
                    errorsContainsOneOf(_errors, passwordErrorCodes)
                  }
                >
                  <Label htmlFor="password">{strings.form.passwordLabel}</Label>
                  <PasswordInput
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    ref={register({ required: true })}
                    placeholder={strings.form.passwordPlaceholder}
                  />
                  <FormErrorMessage fontSize="0.75rem">
                    <NormalText
                      text={
                        messageForCodes(_errors, passwordErrorCodes) ||
                        strings.form.passwordValidation
                      }
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <Button
                    colorScheme="teal"
                    background="teal.600"
                    color="white"
                    width="100%"
                    type="submit"
                    shadow="sm"
                    isLoading={signInFetching}
                    _hover={{ background: 'teal.500' }}
                  >
                    {strings.vintraceLogin.button}
                  </Button>
                </FormControl>
              </>
            </Flex>
          </Form>
          <Disclaimer links={links} />
        </Col>
      </Centered>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="500" fontSize="1rem">
            {strings.initialLoginModal.title}
          </ModalHeader>
          <ModalBody fontSize="0.875rem">
            {strings.initialLoginModal.appDescription}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
