import React, { useEffect, useState } from 'react';
import {
  Label,
  TextInput,
  Col,
  useQueryString,
  errorsContainsOneOf,
  NormalText,
} from '@carafe/components';
import { useIsCarafedQuery } from '@generated/graphql';
import { useForm } from 'react-hook-form';
import { Button, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Form } from '@components/account/Form';
import { strings } from '@localisation';
import { useHistory } from 'react-router-dom';
import { Routes } from '@routes';
import { Centered } from '@layout';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { FormHeader, FormLogo } from '@components/forms';
import { errorCodeParser, messageForCodes } from '@errors';
import { CentralAuthErrorCode } from '@carafe/errors';
import { usePreventEternalRedirect, useLongPress } from '@hooks';

type FormData = {
  customerCode: string;
};
const go = `https://go.vintrace.net`;

const customerCodeErrorCodes = [CentralAuthErrorCode.INVALID_CUSTOMER_CODE];

export const CustomerCode = (): JSX.Element => {
  const history = useHistory();

  const [clientId, redirectUri, customerCode, sandboxManagement] =
    useQueryString([
      'clientId',
      'redirectUri',
      'customerCode',
      'sandboxManagement',
    ]);

  const isMobile = clientId !== null && redirectUri !== null;

  const [_customerCode, _setCustomerCode] = useState(
    customerCode && !isMobile ? customerCode : '',
  );

  const [{ data, fetching, error: carafedError }] = useIsCarafedQuery({
    pause: !_customerCode,
    variables: { customerCode: _customerCode },
  });

  const { register, handleSubmit, errors } = useForm<FormData>({
    defaultValues: {
      customerCode: customerCode || undefined,
    },
  });

  const { increment, purge, redirects, maxAllowedRedirects } =
    usePreventEternalRedirect();
  const onEvents = useLongPress(purge, 1000);
  const _errors = errorCodeParser(carafedError?.graphQLErrors);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!isMobile) {
      if (redirects >= maxAllowedRedirects) {
        // don't redirect, show error.
        return;
      }
      // not carafed and not mobile app -> send to regular vintrace
      else if (!data.isCarafed) {
        increment(_customerCode);
        window.location.href = `${go}/${_customerCode}`;
        return;
      }
      // send to the regular sign in page with customer code in to
      else {
        purge();
        let path = `${Routes.SignInSignUp}?customerCode=${_customerCode}`;
        if (sandboxManagement?.toLowerCase() === 'true') {
          path += '&sandboxManagement=true';
        }
        history.push(path);
      }
    }
    // Set the customer code in the search params to the one from component
    // state, as that will be the most correct value to use in the event the
    // mobile app populates the form but then the user changes the code.
    const params = new URLSearchParams(window.location.search);
    params.set('customerCode', _customerCode);
    // not carafed and is mobile app -> send to username/password login
    if (!data.isCarafed && isMobile) {
      history.push(`${Routes.VintraceLogin}?${params.toString()}`);
      return;
    }
    // carafed and is mobile app -> send to email/password/social login
    if (data.isCarafed && isMobile) {
      history.push(`${Routes.AppLogin}?${params.toString()}`);
      return;
    }
  }, [
    _customerCode,
    data,
    history,
    increment,
    isMobile,
    purge,
    redirectUri,
    redirects,
    maxAllowedRedirects,
    sandboxManagement,
  ]);

  const onSubmit = ({ customerCode }: FormData) => {
    // If the form was manually submitted when an error is displaying then we
    // presume the user wants to give it a go anyway, so clear the error and
    // allow to proceed.
    if (redirects >= maxAllowedRedirects) {
      purge();
    }
    _setCustomerCode(customerCode.trim());
  };

  return (
    <Centered>
      <Col alignItems="center" maxWidth="21rem">
        <div {...onEvents}>
          <FormLogo />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormHeader title={strings.customerCode.title} />
          <FormControl
            px="1rem"
            mb={5}
            isInvalid={
              redirects >= maxAllowedRedirects ||
              !!errors.customerCode ||
              errorsContainsOneOf(_errors, customerCodeErrorCodes)
            }
          >
            <Label htmlFor="customerCode">{strings.customerCode.label}</Label>
            <TextInput
              id="customerCode"
              name="customerCode"
              autoCapitalize="off"
              min={3}
              max={15}
              ref={register({
                required: {
                  message: strings.customerCode.validation,
                  value: true,
                },
                minLength: {
                  message: strings.customerCode.validation,
                  value: 3,
                },
                maxLength: {
                  message: strings.customerCode.validation,
                  value: 15,
                },
              })}
              placeholder={strings.customerCode.placeholder}
            />
            <FormErrorMessage fontSize="0.75rem">
              <NormalText
                text={
                  (errors.customerCode?.message ||
                    messageForCodes(_errors, customerCodeErrorCodes)) ??
                  strings.tooManyRedirects
                }
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl px="1rem">
            <Button
              colorScheme="teal"
              background="teal.600"
              color="white"
              width="100%"
              type="submit"
              shadow="sm"
              isLoading={fetching}
              _hover={{ background: 'teal.500' }}
            >
              {strings.customerCode.button}
              <IoChevronForwardOutline />
            </Button>
          </FormControl>
        </Form>
      </Col>
    </Centered>
  );
};
