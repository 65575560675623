import { Flex, Spinner, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const getStaticBase = () => {
  const { host } = window.location;
  if (host.includes(':3000')) {
    return `https://static.dev.vintrace.app/marketing`;
  }
  const staticBase = host.replace('auth', 'static');
  return `https://${staticBase}/marketing`;
};

const staticBase = getStaticBase();

const Marketing = (): JSX.Element | null => {
  const [imgSrc, setImgSrc] = useState('');
  const [href, setHref] = useState<string>();

  useEffect(() => {
    async function fetchHref() {
      try {
        const res = await fetch(`${staticBase}/link.txt`);
        const href = await res.text();
        setHref(href);
      } catch {}
    }
    fetchHref();
  }, [imgSrc]);

  const img = new window.Image();
  img.src = `${staticBase}/image.png`;

  img.onload = () => setImgSrc(img.src);

  return (
    <Flex
      as={Link}
      width="350px"
      flexDir="column"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="1.25rem"
      backgroundImage={`url(${imgSrc})`}
      backgroundPosition="center"
      backgroundSize="cover"
      isExternal
      href={href ?? '#'}
      style={{ aspectRatio: '3/5', display: 'inline-flex' }}
    >
      {!imgSrc && <Spinner color="gray" speed="0.66s" />}
    </Flex>
  );
};

export default React.memo(Marketing);
