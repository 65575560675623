import { createContext } from 'react';

export interface AuthContextInterface {
  isSignedIn: boolean;
  setToken: (token: string) => void;
}

/**
 * @ignore
 */
/* istanbul ignore next */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext: AuthContextInterface = {
  isSignedIn: false,
  setToken: stub,
};

/**
 * The Auth Context
 */
export const AuthContext = createContext<AuthContextInterface>(initialContext);
